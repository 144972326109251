/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {

  .footer2 {
    background-image: url('../src/static/footer2Background2.svg');
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    width: 100%;
    background-size: 125% 82%;
  }
}



/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 787.98px) {
  

  footer .container-main2 {
    width: 100%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    /* grid-gap: 20px; */
    grid-row-gap: 20px;
    padding: 12px 0px 12px 0px;
    grid-template-areas:
    "F2-sec-1-a F2-sec-1-a F2-sec-1-a F2-sec-1-a"
    "F2-sec-2-a F2-sec-3-a F2-sec-4-a F2-sec-5-a";
  }

  footer .container-main2 .F2-sec-1 {
    display: grid;
    grid-template-columns: 50% 50%; /* Two columns with 50% width each */
    grid-template-areas: "part1 part2";
}

footer .container-main2 .F2-sec-1 .part1 {
    grid-area: part1; /* Assigning the first part to grid area part1 */
}

footer .container-main2 .F2-sec-1 .part2 {
    grid-area: part2; /* Assigning the second part to grid area part2 */
    text-align: end;
}




  .F2-sec-1 {
    grid-area: F2-sec-1-a;
    
  }

  .F2-sec-2 {
    grid-area: F2-sec-2-a;
    margin-bottom: 17px;
  }

  .F2-sec-3 {
    grid-area: F2-sec-3-a;
    margin-bottom: 17px;
  }

  .F2-sec-4 {
    grid-area: F2-sec-4-a;
    margin-bottom: 17px;
  }

  .F2-sec-5 {
    grid-area: F2-sec-5-a;
    margin-bottom: 17px;
  }
  footer .app-button-center1 {
    display: flex;
  flex-wrap: wrap;
  }
  .footer2 {
    background-image: url('../src/static/footer2Background2.svg');
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    width: 100%;
    background-size: 109% 86%;
  }


}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {


  .footer2 {
    background-image: url('../src/static/footer2Background3.svg');
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    width: 100%;
    background-size: 100% 100%;

  }
  footer .container-main2 {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    padding: 12px 0px 12px 0px;
    grid-template-areas:
    "F2-sec-1-a"
    "F2-sec-2-a"
    "F2-sec-3-a"
    "F2-sec-4-a"
    "F2-sec-5-a";
  }


  .F2-sec-1 {
    grid-area: F2-sec-1-a;
    text-align: center;
  }

  .F2-sec-2 {
    grid-area: F2-sec-2-a;
    margin-bottom: 17px;
    text-align: center;
  }

  .F2-sec-3 {
    grid-area: F2-sec-3-a;
    margin-bottom: 17px;
    text-align: center;
  }

  .F2-sec-4 {
    grid-area: F2-sec-4-a;
    margin-bottom: 17px;
    text-align: center;
  }

  .F2-sec-5 {
    grid-area: F2-sec-5-a;
    margin-bottom: 17px;
    text-align: center;
  }

  .sociallinks{
    display: flex; 
    justify-content: center;
  }

  footer .app-button-center1 {
    display: flex;
  flex-wrap: wrap;
  justify-content: center;
  }

  .defoutl-footer2 .header {
    justify-content: center;
  }



  .sociallinks2{
    justify-content: center;
  }


  .hover-drop:hover>.sub-menu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    z-index: 1;
    transform: translateX(144px);
    
  }

  .hover-drop .sub-menu {
    position: absolute;
    top: 100%;
    background: #fff;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 10%);
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 10%);
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    padding: 5px 15px;
    min-width: 177px;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
    visibility: hidden;
    z-index: -100;
  }

  .doctors-name{
    margin-left: 0px !important;
  }
}

/* custom lIMIT */

@media (max-width: 787.98px) {
  .gac.col-6 {
    width: 50% !important;
  }

  .big-Link {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    text-transform: uppercase;

    color: #ff0055;

    text-decoration: none;
  }

  .header-1.home-sec {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #121212;
    margin-bottom: 3px;
  }

  .sub-heading {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: #121212;
  }

  .header-1.home-sec2 {
    margin-top: 18px;
  }

  .brand-sec-margin {
    padding-top: 0px;
    padding-bottom: 45px;
  }

  .brand-sec-margin {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .gci .lowerContent .item-name {
    margin-bottom: 2px;
    font-size: 13px;
    line-height: 16px;
  }

  .gci .lowerContent .item-count {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #575757;
  }

  .pci {
    padding: 7px 7px;
  }

  .pci .upperContent a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px 8px 8px 8px;
  }

  .pci .lowerContent .product-name {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: block;
    color: #121212;
    margin-bottom: 3px;
    margin-top: 4px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .pci .lowerContent {
    margin: 7px 7px 0px 7px;
  }

  .pci .upperContent .product-disc {
    position: absolute;
    top: 7px;
    left: 7px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    color: #fff;
    background: #ff7020;
    border-radius: 8px;
    padding: 3px 8px;
  }

  .rx-type {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    background: #0368b5;
    border-radius: 8px;
    color: #fff;
    padding: 3px 8px;
  }

  .product-op-icon {
    display: flex;
    justify-content: end;
  }

  .wish-icon {
    width: 26.11px;
    height: 26.11px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid transparent;
    cursor: pointer;
  }

  .wish-icon img {
    width: 60%;
    height: 60%;
  }

  .pci .lowerContent .product-dp {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 0px;
    color: #151515;
  }

  .pci .lowerContent .product-op {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 9px;
    color: #a9a9a9;
    text-decoration: line-through;
    height: 16px;
    display: inline-block;
    margin-left: 11px;
  }

  .mobile-header {
    position: fixed;
    z-index: 6;
    background: #fff;

    width: 100%;
    margin-bottom: 0px !important;
    display: grid;
    grid-template-columns: 15% 50% 35% !important;
    grid-template-rows: auto;
    padding: 10px 0px 0px 0px !important;
    grid-template-areas:
      "mga-btn mga-logo mga-user "
      "mga-srch mga-srch  mga-srch";
  }

  .mobile-header .mg-srch {
    background: var(--primary-color);
    padding: 10px 0px;
  }

  .mg-btn {
    grid-area: mga-btn;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mg-logo {
    grid-area: mga-logo;
    display: flex;
    align-items: center;
  }

  .mg-logo .logoheader {
    height: 43px;
  }

  .mg-user {
    grid-area: mga-user;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mg-srch {
    grid-area: mga-srch;
    margin-top: 2px;
  }

  .main-ecomm {
    padding-top: 136px;
  }

  .space-at-top {
    padding-top: 0px;
  }

  .MuiDrawer-root {
    top: 124px !important;
  }

  .MuiDrawer-root .MuiBackdrop-root {
    top: 124px !important;
  }

  .MuiDrawer-root .MuiDrawer-paperAnchorLeft {
    top: 124px !important;
    width: 100%;
    background: var(--primary-color);
    box-shadow: none !important;
  }

  .drawer-sec .header-text {
    margin: 10px 0px;
    display: block;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;

    color: #fff;
  }

  .drawer-sec .header-text.active {
    font-size: 24px;
    line-height: 26px;
  }

  .drawer-sec {
    margin: 0px !important;
    flex-grow: 1;
  }

  .drawer-sec .col-12 {
    padding: 5px 15px;
  }

  .drawer-parent {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .drawer-logout {
    height: 125px;
    padding: 0px 20px;
  }

  .drawer-logout button {
    margin: 0px;
  }

  .carousel-indicators {
    bottom: -2px !important;
  }

  .carousel-control-next {
    height: 41px;
    width: 41px;
  }

  .carousel-control-prev {
    height: 41px;
    width: 41px;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  footer .container-main {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 0px;
    padding: 0px 20px;
    margin: 28px 0px 0px 0px;
    grid-template-areas:
      "F-sec-1-a "
      "F-sec-2-a  "
      "F-sec-3-a  "
      "F-sec-4-a  "
      "F-sec-5-a  ";
  }


  .F-sec-1 {
    grid-area: F-sec-1-a;
    text-align: center;
  }

  .F-sec-2 {
    grid-area: F-sec-2-a;
    margin-bottom: 17px;
  }

  .F-sec-3 {
    grid-area: F-sec-3-a;
    margin-bottom: 17px;
  }

  .F-sec-4 {
    grid-area: F-sec-4-a;
    margin-bottom: 17px;
  }

  .F-sec-5 {
    grid-area: F-sec-5-a;
    margin-bottom: 17px;
  }


  footer .app-button-center {
    display: flex;
    justify-content: center;
    margin-top: 0px;
  }
  

  .img-footer-cont {
    padding: 38px 20px 10px 20px;
  }

  .mg-srch .big-input {
    box-shadow: none !important;
  }

  .col-12 .outlet-parent > div {
    flex-grow: 1;
    border-right: none;
    padding-right: 12px;
  }

  .MuiAutocomplete-popper {
    width: 90% !important;
    right: 50% !important;
    transform: translateX(-50%);
  }

  .MuiAutocomplete-root .MuiFormControl-root .MuiInput-root {
    padding-right: 22px !important;
  }

  .ql-parent {
    grid-template-columns: 74% 30% !important;
  }

  .ql-name-sec-h {
    font-size: 16px;
  }

  .tworem-p-r {
    padding-right: calc(var(--bs-gutter-x) * 0.2);
  }

  .tworem-p-l {
    padding-left: calc(var(--bs-gutter-x) * 0.2);
  }

  div.margin-for-cols > div {
    margin: 7px 0px;
  }

  .profile-block .account-name {
    width: 100%;
    display: block;
    text-align: center;
  }

  .account-photo {
    top: -55px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    width: 130px;
    height: 130px;
  }

  .filter-main {
    display: none;
  }

  .profile-block .account-edit-btn {
    display: none;
  }

  .account-edit-btn-div {
    top: -69px;
    position: absolute;
    text-align: center;
    right: 0px;
    display: block !important;
  }

  .profile-block.col-md-2 {
    display: none;
  }

  .account-hero {
    height: 138px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fefbf3;
    background: -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, #f3fef7),
      color-stop(100%, #ffffff)
    );
    background: -webkit-linear-gradient(top, #f3fef7 0%, #ffffff 100%);
    background: linear-gradient(to bottom, #f3fef7 0%, #ffffff 100%);
    position: relative;
    margin-top: 19px;
    margin-bottom: 19px;
  }

  .shape1-acc {
    bottom: -21px;
    left: 0px;
  }

  .shape2-acc {
    top: 15%;
    left: 243px;
  }

  .shape3-acc {
    bottom: -21px;
    /* right: -19px; */
    left: 85%;
  }

  .header-profile {
    visibility: hidden;
  }

  .edit-profile {
    flex-direction: column-reverse;
  }

  .img-container-edit-profile > img {
    width: 180px;
  }

  .img-container-edit-profile {
    width: 180px;
  }

  .img-container-mobile {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .account-general .address-form {
    border: none;
    padding-top: 0px;
  }

  .address-create {
    margin-bottom: 31px;
  }

  .account-general {
    margin-top: 0px;
  }

  .address-col-mobile {
    padding: 0px !important;
  }

  .header-1 {
    font-weight: 500;
    font-size: 22px;
    line-height: 19px;
    color: #121212;
    margin-bottom: 15px;
    display: inline-block;
  }

  .filter-mobile {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: #ffffff;
    box-shadow: 0px 3px 4px rgb(115 115 115 / 25%);
    border-radius: 50%;
    color: #000;
    position: fixed;
    right: 27px;
    bottom: 27px;
    background: radial-gradient(
        86.05% 1188.53% at 4.27% 13.95%,
        #00dee0 0%,
        #55fce4 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  }

  .filter-mobile-tabs-parent {
    display: flex;
    height: 100%;
  }

  .filter-sub {
    margin: 0px !important;
  }

  .filter-category-list {
    margin: 0px !important;
  }

  .MuiPaper-root.MuiPaper-elevation {
    width: 100% !important;
  }

  .MuiTabs-root.MuiTabs-vertical {
    width: 91px !important;
    background: #f3f3f3;
  }

  .price-input-grid {
    width: 257px;
  }

  .filter-mobile-master {
    height: 100%;
  }

  .MuiButtonBase-root.MuiTab-root {
    text-transform: none !important;
    font-weight: 400 !important;
    border-bottom: 1px solid #d0d0d0;
  }

  .MuiButtonBase-root.MuiTab-root.Mui-selected {
    background: #fff;

    color: rgba(0, 0, 0, 0.6) !important;
    font-weight: 600 !important;
  }

  .filter-mobile-tabs-parent .MuiTabs-indicator {
    background: none !important;
  }

  .tab-parent {
    margin-left: 20px;
    margin-right: 20px;
    flex-grow: 1;
  }

  .filters {
    padding: 15px 16px;
  }

  .filters .arr-btn {
    padding-right: 10px;
  }

  .prduct-list-parent {
    min-height: 77vh;
    position: relative;
  }

  .fl-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .outlet-parent {
    position: relative;
  }

  .outlet-parent .MuiInput-root.MuiInput-underline.MuiInputBase-root {
    font-size: 13px;
  }

  .outlet-parent .MuiAutocomplete-root {
    margin-top: 8px;
  }

  .select-store {
    position: absolute;
    top: 4px;
    left: 34px;
    font-size: 10px;
  }

  .reset-filter {
    float: right;
    padding: 15px 16px;
    font-weight: 500;
    font-size: 19px;
    line-height: 19px;
    color: #ff0055;
    margin-bottom: 15px;
    display: inline-block;
  }

  .order-parent {
    display: grid;
    grid-template-columns: 33% 34% 33% !important;
    grid-template-rows: auto;
    padding: 22px 14px 0px 14px;
    grid-template-areas:
      "order-area-id order-area-date order-area-total "
      "order-area-status order-area-status order-area-status "
      "order-area-plist order-area-plist order-area-plist "
      " order-area-button order-area-button order-area-button"
      " order-area-divider order-area-divider order-area-divider";
    background: #ffffff;
    border: none;
    border-radius: 0px;
    margin-bottom: 0px;
  }

  .order-divider {
    grid-area: order-area-divider;
    display: inline !important;
    margin-top: 17px;
    margin-bottom: 16px;
  }

  .order-button {
    grid-area: order-area-button;
    margin-top: 12px;
  }

  .order-status {
    margin: 22px 0px 14px 0px;
  }

  .order-det-row-m {
    margin: 0px !important;
  }

  .orderdetails-parent {
    padding: 15px 10px;
    margin-bottom: 20px;
  }

  .od-list {
    margin: 23px 0px 0px 0px !important;
  }

  .od-summ {
    margin: 13px 0px 0px 0px !important;
  }

  .order-det-mobi-col {
    padding: 0px !important;
  }

  .pd-cart-button-grp {
    padding: 2px 7px !important;
  }

  .pd-cart-button-grp button {
    padding: 2px 5px !important;
  }

  .pl-sp-qty {
    margin-left: 13px;
    font-size: 15px;
    line-height: 32px;
  }

  .pd-cart-button-grp > span {
    padding: 0px 9px;
  }

  .cart-details-item {
    margin-left: 8px;
    margin-right: 8px;
  }

  .pl-pd-mobile {
    padding-left: 0px !important;
  }

  .cart-details-rfc button {
    padding: 2px 7px !important;
    margin-top: 8px;
  }

  .mobile-radio .form-check-radio input:checked + label:after {
    transform: translateY(-51%) !important;
  }

  .os-parent-button .btn {
    margin-top: 0px;
  }

  .cart-details-subtotal {
    visibility: hidden !important;
    height: 10px;
  }

  .login-box {
    padding: 21px 0px;
    border: none;
    box-shadow: none;
  }

  .user-profile-logout {
    display: block;
  }

  .col-12 .outlet-parent .right-border {
    display: none;
  }

  .mobile-grid > .col-6:nth-child(2n) {
    padding-left: 0.4rem;
  }

  .mobile-grid > .col-6:nth-child(2n + 1) {
    padding-right: 0.4rem;
  }

  .address-col-mobile > .account-general {
    margin: 0px !important;
  }

  .csu-optional {
    margin: 20px 0px;
  }

  .create-user-mobile-upper {
    display: none;
  }

  .create-user-mobile-lower {
    display: block;
  }

  .from-cust-blocks {
    padding: 4px 8px;
    font-size: 13px;
  }

  .log-main {
    width: auto !important;
    height: 100% !important;
  }

  .row-app-section {
    position: relative;
    padding: 50px 0px 330px 0px;
    overflow: hidden;
    margin: 0px !important;
  }

  .row-app-section:before {
    top: -171%;
    left: -126%;
    height: 664%;
  }

  .row-app-section .screenshot-home-app {
    position: absolute;
    width: 289px;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }

  .top-text {
    font-weight: 400;
    font-size: 7.35872px;
    line-height: 15px;
    /* identical to box height, or 200% */

    color: #ffffff;
  }

  .app-btton img {
    margin-right: 14px;
    width: 25px;
  }

  .bottom-text {
    font-weight: 900;
    font-size: 12.2645px;
    line-height: 15px;
    /* identical to box height, or 120% */

    color: #ffffff;
  }

  .header-app-section {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;
    color: #ffffff;
    margin-bottom: 9px !important;
    display: inline-block;
  }

  .text-app-section {
    color: #fff;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #212529;
    margin-bottom: 0px !important;
    display: inline-block;
  }

  .sl-ftr .sociallinks {
    margin-left: 0px;
    justify-content: center;
    margin-top: 0px;
  }

  section.d-none svg {
    display: block !important;
  }

  .home-head {
    font-size: 23px;
  }

  .cat-widget .item-name {
    font-size: 13px !important;
    line-height: 16px !important;
  }

  .round-btn-header {
    width: 27.1px;
    height: 27.1px;
    margin: 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px solid #f8f8f8;
    box-shadow: 0px 8px 20px rgb(207 207 207 / 25%);
    border-radius: 100px;
    position: relative;
  }

  .round-btn-header svg {
    transform: scale(0.8);
  }

  .wish-icon svg {
    transform: scale(0.7);
  }

  footer {
    overflow: hidden;
  }

  .slick-container .slick-slider > .slick-arrow {
    opacity: 1;
    visibility: visible;
    -moz-transition: visibility 0.25s ease, opacity 0.45s ease;
    -webkit-transition: visibility 0.25s ease, opacity 0.45s ease;
    -o-transition: visibility 0.25s ease, opacity 0.45s ease;
    -ms-transition: visibility 0.25s ease, opacity 0.45s ease;
  }

  .slick-container .slick-slider .slick-prev {
    left: -5px;
  }

  .slick-container .slick-slider .slick-next {
    right: -5px;
  }

  .upload-pres {
    padding: 22px 16px;
    border: none;
    border-radius: 19px;
    position: relative;
    /* background-color: var(--hero-back) !important; */
  }

  .upload-pres .up-h-incs {
    margin: 20px 0;
  }

  .up-single-item {
    margin: 15px 0;
  }

  .up-mini-icn-parent {
    display: flex;
    align-items: center;
  }

  .upload-pres-sec .container {
    padding: 40px 15px;
  }

  .upload-pres h4 {
    font-size: 17px !important;
  }

  .upload-pres p {
    font-size: 14px !important;
  }

  .upload-pres .address-form-button {
    width: auto !important;
  }

  .upload-pres {
    position: relative;
  }

  .img-mobile-image {
    position: absolute;
    position: absolute;
    bottom: 7px;
    right: 15px;
  }

  .upload-back img {
    height: 90px;
  }

  .upload-pres-sec {
    padding-top: 36px !important;
  }

  .log-main {
    height: auto !important;
    max-height: auto;
    width: 100% !important;
  }

  .p-temprice {
    padding-right: 0px;
  }

  .focusedSearchParent {
    z-index: 7;
  }

  .focusedSearchParent .header-main {
    top: 50px;
  }

  .header-parent {
    display: grid;
    grid-template-columns: 100% !important;
    grid-template-rows: auto;
    padding: 0px 0px 20px 0px !important;
    grid-template-areas: " input ";
  }

  .header-input {
    margin: 0px 10px;
  }

  .focusedSearchR .foc-wrapper {
    width: 100% !important;
  }

  .focused-input {
    margin-right: 0px;
    margin-left: 0px;
  }

  .focusedSearch .info-box {
    margin: 17px 0px 0px 0px;
  }

  .big-input {
    grid-template-columns: 50% 50% !important;
  }

  .out-btn {
    width: 95%;
  }

  .INoutlet-selector,
  .INsearch-product {
    padding: 0px 15px;
  }

  .INoutlet-selecto4r .out-btn {
    text-overflow: ellipsis;
    /* enables ellipsis */
    white-space: nowrap;
    /* keeps the text in a single line */
    overflow: hidden;
    /* keeps the element from overflowing its parent */
  }

  .out-btn svg {
    width: 16px;
  }

  .out-btn span {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    padding-left: 8px;
  }

  .foc-wrapper {
    width: 100%;
  }

  .focusedSearchParent .focused-input .INoutlet-selector {
    border: none;
  }

  .pci .product-disc {
    font-size: 12px;
    line-height: 12px;
  }

  .wish-prod-det {
    position: relative;
    top: -453px;
    right: -324px;
    z-index: 1;
  }

  .pd-c .image-gallery {
    margin-right: 0px !important;
  }

  .bannerSection {
    padding-bottom: 0 !important;
  }

  .main_expired .expired .upper h2{
    font-size: 22px;
  }
  .main_expired .expired .contect .subCon p {
    font-size: 14px;
  }

  .main_expired .expired .contect .subCon .contect_info .info svg{
    width: 18px;
    height: 18px;
  }

  .main_expired .expired .contect .subCon .contect_info .info span{
    font-size: 14px;
  }
}



@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 650px !important;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 880px  !important;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1012px  !important;
  }


}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1090px  !important;
  }


}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px  !important;
  }


}