@use "../abstracts" as abs;

.requestMainBox{
    margin-top: 160px;
    
    @include abs.respond-to(handheld2) {
        margin-top: 50px;
    }
}

.submitRequestMain {
    background-color: #fff;
    border-radius: 33px;
    padding: 80px;
    margin: 50px auto;

    @include abs.respond-to(smallDesktop2) {
        padding: 40px;
        margin: 20px auto;
        border-radius: 22px;

        @include abs.respond-to(handheld2) {
            border-radius: 12px;
            padding: 20px;
        }
    }

    .submitRequest {
        display: grid;
        grid-template-columns: 6fr 4fr;
        gap: 60px;

        @include abs.respond-to(smallDesktop2) {
            gap: 20px;

            @include abs.respond-to(handheld2) {
                flex-direction: column;
            }
        }

        .left {
            display: flex;
            flex-direction: column;
            gap: 30px;

            .heading {
                .title {
                    color: abs.$primary-color;
                    font-size: abs.$heading-lg;
                }

                .subTitle {
                    color: abs.$text-blue;
                    font-size: abs.$heading-sm;
                    ;
                }
            }

            .checkboxs {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .info-item {
                    display: flex;
                    gap: 10px;
                    align-items: center;

                    .text {
                        font-size: abs.$heading-xs;
                        color: abs.$text-blue;
                        font-weight: abs.$font-regular;

                        strong {
                            font-weight: abs.$font-semibold;
                        }
                    }
                }

            }
        }

        .right {

            .submitArea {
                .heading {
                    .title {
                        color: abs.$primary-color;
                        font-size: abs.$heading-lg;
                    }
                }

                display: flex;
                flex-direction: column;
                gap: 10px;

                .inputBox {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    label {
                        font-family: abs.$font-family;
                        font-size: abs.$body;
                        font-weight: abs.$font-medium;
                        color: #10002E;

                        span {
                            color: abs.$warning-color;
                            font-weight: 500;
                            font-size: 16px;
                        }
                    }

                    .input {
                        border: 2px solid abs.$corner-color;
                        padding: 12px 22px;
                        border-radius: 8px;
                        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
                        font-family: abs.$font-family;
                        font-size: abs.$body;
                        font-weight: abs.$font-regular;

                        &::placeholder {
                            color: abs.$text-gray;
                        }
                    }

                    .numberSelector {
                        display: flex;
                        gap: 5px;

                        .countryCOdeparent {
                            width: 85px;

                            .MuiAutocomplete-root {
                                font-family: abs.$font-family;

                                .MuiFormControl-root {
                                    font-family: abs.$font-family;

                                    .MuiInputBase-root {
                                        font-family: abs.$font-family;
                                        padding-right: 0px;

                                        .MuiButtonBase-root {
                                            top: 8px;
                                            left: 5px;
                                        }
                                    }
                                }
                            }
                        }

                        .numInput {
                            border: none;
                        }
                    }


                }

                .termsArea {
                    display: flex;
                    gap: 10px;

                    p {
                        font-family: abs.$font-family;
                        font-size: abs.$body;
                        font-weight: abs.$font-semibold;
                        color: abs.$primary-color;
                        line-height: 21px;
                    }

                    .checkBox {
                        width: 20px;
                        height: 20px;
                        appearance: none;
                        border: 1px solid abs.$primary-color;
                        border-radius: 4px;
                        margin-top: 5px;
                        position: relative;
                        cursor: pointer;

                        &:checked {
                            background-color: abs.$primary-color;
                            background-image: url("data:image/svg+xml;utf8,<svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'><rect x='0.390625' y='0.046875' width='23.9076' height='23.9076' rx='4.78152' fill='%23263077'/><path d='M6.96484 12.8409L10.3802 16.188L17.7233 7.82031' stroke='white' stroke-width='2.39076' stroke-linecap='round' stroke-linejoin='round'/></svg>");
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                    }
                }

                .submitButtons {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    gap: 20px;

                    @include abs.respond-to(smallDesktop2) {
                        gap: 10px;
                    }

                    button {
                        font-size: abs.$button-md !important;

                        @include abs.respond-to(smallDesktop2) {
                            font-size: abs.$button-xl !important;

                            @include abs.respond-to(handheld2) {
                                font-size: abs.$button-md !important;
                                padding: 10px 20px;
                            }
                        }
                    }
                }
            }

        }
    }
}



.succesMain {
    background-color: #fff;
    border-radius: 33px;
    padding: 80px;
    margin: 50px auto;

    .successMessage {
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;
        text-align: center;

        h1 {
            font-size: abs.$heading-lg;
            color: abs.$primary-color;
        }

        h3 {
            font-size: abs.$heading-md;
            color: #0F1531;
        }

        p {
            font-size: abs.$heading-sm;
            color: abs.$text-blue;

            a {
                color: abs.$primary-color;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;

                }
            }
        }

        .link {
            font-size: abs.$heading-md !important;
        }
    }
}