:root {
  --light-primary: #ffffff;

  --primary-color: #7cb940;
  --primary-color-light: #7cb9402e;

  --linear-gradient: linear-gradient(47.55deg, #539b44 8.54%, #7cb940 92.2%);

  --wb-linear-gradient: -webkit-linear-gradient(47.55deg,
      #539b44 8.54%,
      #7cb940 92.2%);

  --linear-gradient-btn: linear-gradient(47.55deg,
      #539b44 8.54%,
      #7cb940 92.2%);

  --wb-linear-gradient-btn: -webkit-linear-gradient(47.55deg,
      #539b44 8.54%,
      #7cb940 92.2%);

  --header-clr: #fff;

  --app-download-back: #222222;

  --btn-stl-clr: #fff;

  --btn-light: #539b44;

  --linear-gradient-hero: linear-gradient(180deg, rgba(0, 247, 87, 0.09) 0%, rgba(255, 255, 255, 0.38) 100%);

  --hero-back: #ebfde9;

  --svg-grad-1: #539b44;
  --svg-grad-2: #7cb940;

  --svg-grad-1b: #000000;
  --svg-grad-2b: #000000;

  --svg-grad-1w: #fff;
  --svg-grad-2w: #fff;

  --svg-grad-d1: #d3d3d3;
  --svg-grad-d2: #d3d3d3;
}

body {
  background: #f5f5f5;
}

.f-dnone {
  display: none !important;
}

.v-h-none {
  visibility: hidden;
}

#svg-grad stop.start-color {
  stop-color: var(--svg-grad-1) !important;
}

#svg-grad stop.end-color {
  stop-color: var(--svg-grad-2) !important;
}

#svg-gradb stop.start-colorb {
  stop-color: var(--svg-grad-1b) !important;
}

#svg-gradb stop.end-colorb {
  stop-color: var(--svg-grad-2b) !important;
}

#svg-gradw stop.start-colorw {
  stop-color: var(--svg-grad-1w) !important;
}

#svg-gradw stop.end-colorw {
  stop-color: var(--svg-grad-2w) !important;
}

#svg-grad-disabled stop.start-color {
  stop-color: var(--svg-grad-d1) !important;
}

#svg-grad-disabled stop.end-color {
  stop-color: var(--svg-grad-d2) !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ecom-solid {
  background-image: var(--linear-gradient-btn);
  -webkit-box-shadow: none;
  background-size: auto 140%;
  border: 0px;
  color: var(--btn-stl-clr);
  width: 100%;
  margin-top: 17px;
  border-radius: 9px;
}

.ecom-solid:hover {
  -webkit-box-shadow: 0px 0px 30px 0px rgb(240 172 80 / 40%) !important;
  color: #fff !important;
}

.ecom-solid:focus {
  -webkit-box-shadow: none !important;

  color: #fff !important;
}

.ecom-outline {
  background-color: #fff;
  border: 0px;
  color: var(--primary-color);
  width: 100%;
  margin-top: 17px;
  border-radius: 9px;
  border: 1px solid var(--primary-color) !important;
}

.ecom-outline:hover,
.ecom-outline:focus {
  background-color: #fff;
  border: 0px;
  color: var(--primary-color);
  width: 100%;
  margin-top: 17px;
  border-radius: 9px;
  box-shadow: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

section {
  padding: 0px 0px 31px 0px;
}

.space-at-top {
  padding-top: 190px;
}

.even-spacer-y {
  margin: 10px 0px;
}

.main-ecomm {
  position: relative;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

/* Font Color */

.fcolor-dark {
  color: #121212;
}

.header-1 {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;

  margin-bottom: 11px;
}

.header-3 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 5px;
}

.sub-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.footer-text .link {
  text-decoration: none;
}

.footer-text .love {
  color: red;
}

.footer-text {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  color: #121212;
}

.big-Link {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: right;
  text-transform: uppercase;
  cursor: pointer;
  background: var(--linear-gradient);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-decoration-line: underline;
}

.big-Link:hover {
  background: var(--linear-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

/* GeneralGridItem */

.gci {
  position: relative;
  width: 100%;
  background: #ffffff;
  border: 1px solid #cbcbcb;
  border-radius: 9px;
  display: block;
  overflow: hidden;
  padding: 0px;
  text-decoration: none;
}

.gci .upperContent {
  height: 0;
  padding-top: 100%;
  position: relative;
}

.gci div.upperContent>div {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gci .lowerContent .item-name {
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: block;
  color: #121212;
  margin-bottom: 7px;

  text-overflow: ellipsis;
  /* enables ellipsis */
  white-space: nowrap;
  /* keeps the text in a single line */
  overflow: hidden;
  /* keeps the element from overflowing its parent */
}

.gci .lowerContent .item-count {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: block;
  color: #121212;
}

.img-fluid-2 {
  height: 100%;
  width: auto;
}

/* ProductGridItem */

.pci {
  position: relative;
  width: 100%;
  background: #ffffff;
  border: 1px solid #cbcbcb;
  border-radius: 9px;
  display: block;
  padding: 12px 12px;
  overflow: hidden;
}

.pci.oos>.upperContent>a,
.pci.oos>.lowerContent,
.pci.oos>.upperContent>span.product-disc {
  opacity: 0.5;
}

.pci .upperContent {
  background: #fff;
  border-radius: 8px 8px 8px 8px;

  height: 0;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.pci .upperContent a {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pci .product-disc {
  position: absolute;
  top: 11px;
  left: 11px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13.1927px;
  line-height: 15px;

  color: #4c4c4c;
  z-index: 2;
  background: #ffeb33;
  border-radius: 4px;

  height: 20.83px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 6px;
}

.pci .upperContent .product-oos {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  /* identical to box height */

  color: #ff0055;
  background: #f4f8ec;
  border-radius: 12px;
  padding: 8px 10px;
}

.pci .upperContent .remove-from-wish {
  position: absolute;
  top: -12px;
  right: -12px;
}

.pci .upperContent .remove-from-wish button {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.pci .upperContent .remove-from-wish button img {
  transform: rotate(45deg);
}

.pci .lowerContent {
  margin: 9px 6px 9px 6px;
}

.product-n-a-b {
  height: 72px;
  max-height: 72px;
}

.pci .lowerContent .product-name {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  text-transform: capitalize;

  color: #151515;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 46px;
}

.pg-bn {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.rx-type {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  background: #0368b5;
  border-radius: 8px;
  color: #fff;
  padding: 5px 10px;
}

.otc-type {
  width: 38px;
  height: 24px;
  display: inline-block;
  background: #0d6efd;
  padding: 6px 11px;
  color: #fff;
  text-align: center;
  border-radius: 21px;
  margin-left: 10px;
}

.pci .lowerContent .product-name a {
  text-decoration: none !important;
  color: #151515 !important;
}

.pci .lowerContent .product-manu {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  min-height: 24px;
  margin-bottom: 0px !important;
  /* identical to box height */

  /* C1/B */
  color: #575757;
}

.pci .lowerContent .product-dp {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;

  /* identical to box height */

  /* C1/A */
  color: #151515;
}

.pci .lowerContent .product-op {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  /* identical to box height */

  /* C1/C */
  color: #a9a9a9;

  height: 16px;
  display: inline-block;
  margin-left: 11px;
}

/* FOoter */

footer {
  position: relative;
  width: 100%;
  height: auto;
  padding: 50px 100x;
  border-radius: 10px 10px 0px 0px;
  background: #fff;
  flex: 0 0 auto;
}

footer .container-main {
  width: 100%;
  display: grid;
  grid-template-columns: 2.5fr 0.8fr 0.8fr 1.5fr;
  grid-gap: 20px;
  padding: 12px 0px 12px 0px;
  
}

.qlinks {
  list-style: none;
  padding-left: 0px;
}

.qlinks li a {
  text-decoration: none !important;
  margin: 10px 0px;
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  /* identical to box height, or 171% */

  color: #212529;
}

footer .copyright {
  padding-bottom: 15px;
  padding-top: 15px;
}

.sociallinks {
  list-style: none;
  padding: 0px;
  display: flex;
  margin-left: 0px;
  margin-top: 25px;
  margin-bottom: 25px;
}

ul.sociallinks li {
  margin-right: 15px;
}

.footer-ph {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  margin: 40px 0px;
  display: block;
  color: #121212;
}

.img-footer-cont {
  padding: 38px 0px 10px 0px;
}

.app-button-center1 a img{
  margin: 2px 4px 2px 0px;
}


/* FOoterv2 */

.container2{
  max-width: 650px !important;
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;
}

footer .container-main2 {
  width: 100%;
  display: grid;
  grid-template-columns: 35% 14% 14% 14% 23%;
  /* grid-gap: 20px; */
  padding: 12px 0px 12px 0px;
  grid-template-areas:
  "F2-sec-1 F2-sec-2 F2-sec-3 F2-sec-4 F2-sec-5";
  box-sizing: content-box;
}

.F2-sec-1 .part1{
  margin-bottom: 24px;
}
.F2-sec-1 .part2 .part2-block{
  margin-bottom: 24px;
}

.F2-sec-1 .part2 .part2-block .plinks2 li a{
  font-size: 18px;
}

.qlinks2 {
  list-style: none;
  padding-left: 0px;
}


.qlinks2 li a {
  text-decoration: none !important;
  margin: 10px 0px;
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  /* identical to box height, or 171% */
  

  color: #263077 !important;
}
.qlinks2 li a img{
  height: 18px;
  width: 18px;

}

.qlinks2 li div.row {
  text-decoration: none !important;
  margin: 23px 0px;
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  /* identical to box height, or 171% */

  color: #212529;
}


.plinks2 {
  list-style: none;
  padding-left: 0px;
}

.plinks2 li a {
  text-decoration: none !important;
  margin: 5px 0px;
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  /* identical to box height, or 171% */
  

  color: #263077;
}
.plinks2 li a img{
  height: 18px;
  width: 18px;

}

.plinks2 li div.row {
  text-decoration: none !important;
  margin: 23px 0px;
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  /* identical to box height, or 171% */

  color: #212529;
}

footer .copyright {
  padding-bottom: 15px;
  padding-top: 15px;
}

.sociallinks2 {
  list-style: none;
  padding: 0px;
  display: flex;
  justify-content: flex-start;
  margin-left: 0px;
  margin-top: 6px;
  margin-bottom: 25px;
}

.sociallinks2 li a img{
  width: 24px;
  height: 24px;
}

ul.sociallinks2 li {
  margin-right: 15px;
}

.footer-ph {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  margin: 40px 0px;
  display: block;
  color: #121212;
}

.img-footer-cont {
  padding: 38px 0px 10px 0px;
}

.log-main2 {
  max-height: 27px;
  margin-bottom: 24px;
}


.footer-text2 .link {
  text-decoration: none;
}

.footer-text2 .love {
  color: red;
}

.footer-text2 {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  color: #121212;
}


.doctors-name{
  margin-left: 33px;
}

.header-3-footer2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  /* identical to box height, or 114% */
  color: var(--primary-color);
  
}

.reach-us-image{
  margin-right: 10px;
}

.doctor-image{
  margin-right: 10px;
  position: relative;
    top: -5px;
}

.defoutl-footer2 {
  margin-top: 20px;
}

.defoutl-footer2 .header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  /* or 150% */

  display: flex;
  align-items: center;
  
  margin-top: 4px;
  /* fg */

  color: #263077;
  margin-bottom: 10px;
  text-align: center;
}

.defoutl-footer2 .header>span {
  /* fg */

  color: #263077;
}
.footer2 {
  background-image: url('../src/static/footer2Background.svg');
  background-repeat: no-repeat; /* Prevent the background image from repeating */
  width: 100%;
  background-size: 100% 85%;
  background-position-x: -58px;
    background-position-y: -11px;

  /* height: 100%; */
}
@media (min-width: 991.98px) and (max-width: 1200px) {

  .footer2 {
    background-image: url('../src/static/footer2Background2.svg');
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    width: 100%;
    background-size: 100% 81%;
    
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {

  .footer2 {
    background-image: url('../src/static/footer2Background.svg');
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    width: 100%;
    background-size: 118% 92%;
    background-position-x: -267px;

  }
}
@media (min-width: 1400px) and (max-width: 1500px) {

  .footer2 {
    background-image: url('../src/static/footer2Background.svg');
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    width: 100%;
    background-size: 92% 56%;
  }
}





/* download showcase */

.download-showcase {
  position: relative;
}

.download-showcase p {
  padding-top: 15px;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: #000000;
}

.shape {
  position: absolute;
}

.shape1 {
  top: 20%;
  left: -14px;
}

.shape1 img {
  width: 99px;
}

.shape2 {
  top: 5%;
  right: 3%;
}

.shape2 img {
  width: 59px;
}

.shape3 {
  bottom: 20%;
  right: 20%;
}

.shape3 img {
  width: 114px;
}

/* Cart Icon */

.wish-icon {
  width: 38.11px;
  height: 38.11px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid transparent;
  cursor: pointer;
}

.wish-icon2 {
  width: 38.11px;
  height: 38.11px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid transparent;
  cursor: pointer;
  position: absolute;
  right: 11px;
  z-index: 2;
  top: 11px;
}

/* Banners */

.banner {
  height: 395px;
}

.carousel-indicators {
  bottom: 31px !important;
}

.carousel-control-next {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: #222;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
}

.carousel-control-prev {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: #222;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
}

.carousel-indicators [data-bs-target] {
  background: #222222;
  border: 1px solid #222222;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  opacity: 1 !important;
}

.carousel-indicators .active {
  background: #fff;
}

/* Header */

.header-main {
  background: #fff;
  position: fixed;
  width: 100%;
  z-index: 5;
  top: 0px;
  left: 0px;
  padding: 20px 0px 0px 0px;
}

.header-parent {
  display: grid;
  grid-template-columns: 15% 60% 25% !important;
  grid-template-rows: auto;
  padding: 0px 0px 20px 0px !important;
  grid-template-areas:
    "logo input button"
    "nav nav nav";
}

.header-logo {
  grid-area: logo;
  display: flex;
  align-items: center;
}

.logoheader {
  height: 43px !important;
  width: auto !important;
}

.header-input {
  grid-area: input;
  display: flex;
  align-items: center;
  margin: 0px 33px;
}

.header-buttons {
  grid-area: button;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-navbar {
  grid-area: nav;
  position: relative;
}

.header-navbar .get-app {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.hlinks {
  display: flex;
  padding: 0px;
  list-style: none;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.header-text {
  padding: 0px 15px;
  text-decoration: none;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;

  color: var(--header-clr);
}

.header-text:hover {
  padding: 0px 15px;
  text-decoration: none;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;

  color: var(--header-clr);
}

/* .header-text:hover {
  background: var(--linear-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
} */

.header-text.active {
  color: var(--header-clr);
  border: 1px solid var(--header-clr);
  padding: 10px 20px;
  border-radius: 20px;
}

.round-btn-header {
  width: 40.1px;
  height: 40.1px;
  margin: 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #f8f8f8;
  box-shadow: 0px 8px 20px rgba(207, 207, 207, 0.25);
  border-radius: 100px;
  position: relative;
}

.round-btn-header .counter {
  position: absolute;
  background: #ff0055;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  font-weight: 500;
  font-size: 10px;
  right: -4px;
  color: #fff;
  top: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.big-input {
  background: #f4f4f4;

  border: 1px solid var(--primary-color);
  border-radius: 43px;
  height: 45.25px;
  margin: 0px 10px;
  overflow: hidden;

  flex-grow: 1;

  display: grid;
  grid-template-columns: 45% 55% !important;
  grid-template-rows: auto;
  padding: 0 !important;
  grid-template-areas: " IPselector  IPselector2";
}

.INoutlet-selector {
  grid-area: IPselector;

  padding: 0px 38px;
  margin: 9px 0px;
  border-right: 1px solid rgba(124, 185, 64, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.out-btn {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.out-btn span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 15px;
  /* Button */

  color: #96a0a8;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  width: 100%;
}

.out-btn input {
  padding-left: 15px;
  color: #96a0a8;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  display: inline-block;
  line-height: 140%;
  width: 100%;
  background: transparent;
  border: none;
}

.out-btn input:focus-visible {
  outline: none !important;
}

.INsearch-product {
  grid-area: IPselector2;

  padding: 0px 38px;
  margin: 9px 0px;
}

/* Header Hero */

.home-hero {
  background-image: var(--linear-gradient-hero);
  padding-bottom: 154px;
  position: relative;

  flex: 0 0 auto;
}

.home-hero::before {
  content: "";

  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  z-index: 0;

  background-image: url(../src/static/section.svg);

  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: 0px -106px;
}

.header-parent-hero {
  display: grid;
  grid-template-columns: 50% 50% !important;
  grid-template-rows: auto;
  padding: 0 !important;
  grid-template-areas:
    " buttondl-h  button-h  "
    "logo-h logo-h  "
    "input-h input-h"
    "nav-h nav-h";
}

.home-hero .shape1 {
  top: 20%;
  left: -56px;
}

.home-hero .shape2 {
  top: -4%;
  right: 33%;
}

.home-hero .shape3 {
  bottom: 21px;
  right: 1px;
}

.header-logo-hero {
  grid-area: logo-h;
  text-align: center;
  margin-top: 4rem;
}

.header-input-hero {
  grid-area: input-h;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 35px 0px 27px 0px;
}

.header-buttons-hero {
  grid-area: button-h;
  display: flex;
  justify-content: end;
  align-items: center;
}

.header-navbar-hero {
  grid-area: nav-h;
}

.header-buttons-download-hero {
  grid-area: buttondl-h;
  display: flex;
  justify-content: start;
  align-items: center;
}

div.header-input-hero div.big-input {
  flex-grow: 0 !important;
  width: 70% !important;
  border: none !important;
}

.hero-h-download-btn {
  background: #fff;
  box-shadow: 0px 3px 4px rgba(210, 210, 210, 0.25);

  border-radius: 111px;
}

.hero-h-download-btn>span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  margin-left: 7px;

  margin-right: 7px;

  color: #000 !important;
}

/* AUto complete Search */

.autocompletesearch {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
}

div.big-input>div.row {
  height: 100%;
  margin: 0px;
}

div.autocompletesearch>input {
  flex-grow: 1;
  border: none;
}

div.autocompletesearch>input:focus-visible {
  outline: 0px !important;
}

.search-results {
  position: absolute;
  top: 45px;
  width: 100%;
  background: white;
  padding: 10px 0px 0px 0px;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
    0 9px 28px 8px #0000000d;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  max-height: 400px;
  overflow-y: auto;
  display: none;
  z-index: -1;
}

.search-results.show {
  opacity: 1;
  display: block;
  z-index: 5;
}

.search-results::-webkit-scrollbar {
  width: 10px;
}

.search-results::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.search-results::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 3px solid transparent;
  background-clip: content-box;
  background-color: #ff0055;
}

.searchIcon {
  margin-right: 7px;
}

.search-results>a {
  cursor: pointer;
  margin: 10px 0px 0px 0px;
  display: flex;
  align-items: center;
  padding: 0px 10px 10px 10px;
  border-bottom: 1px solid #f1f3f4;
  text-decoration: none;
}

.search-results>div {
  cursor: pointer;
  margin: 10px 0px 0px 0px;
  display: flex;
  align-items: center;
  padding: 0px 10px 10px 10px;
  border-bottom: 1px solid #f1f3f4;
  text-decoration: none;
}

.search-item-icon {
  max-height: 39px;
  height: 39px;
  margin-right: 13px;
}

.search-item-name {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: #121212;
}

/* Alert */

.alert-parent {
  position: fixed;
  z-index: 100;
  width: 100%;
  top: 0px;
  left: 0px;
}

.alert-success {
  box-shadow: 0 0 1rem 0 rgb(0 0 0 / 0%);
  border-radius: 5px;
  background-color: rgb(100 223 7 / 66%);
  backdrop-filter: blur(14px);
  color: white;
}

.show-alert {
  animation: movein 0.5s ease forwards;
}

.hide-alert {
  animation: moveout 0.5s 3s ease forwards;
}

@keyframes movein {
  from {
    left: -2000px;
  }

  to {
    left: 0px;
  }
}

@keyframes moveout {
  from {
    left: 0px;
  }

  to {
    left: -2000px;
  }
}

.alert-danger {
  display: block;
  box-shadow: 0 0 1rem 0 rgb(0 0 0 / 0%);
  border-radius: 5px;
  background-color: rgb(255 0 0 / 66%);
  backdrop-filter: blur(5px);
  color: white;
}

/* Filter */

.filter-main {
  display: flex;
  flex-direction: column;
}

.filter-parent {
  background: #ffffff;
  border: none;
  border-radius: 4px;

  padding: 20px 33px;
}

.filter-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  background: var(--linear-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.filter-subheading {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  color: #121212;
}

.filter-sub {
  margin-top: 22px;
  margin-bottom: 40px;
}

.filter-category-list {
  margin-top: 10px;
  margin-bottom: 10px;
}

div.filter-category-list>div.row {
  padding: 6px 0px;
}

.filter-counter-column {
  display: flex;
  align-items: center;
  justify-content: end;
}

.filtercategory-counter {
  background: #fff5f9;
  border-radius: 9px;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #ff0055;
  display: block;
  padding: 4px 8px;
  width: 38px;
  height: 21px;
}

.filtercategory-name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #121212;
}

.form-check-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-left: 34px;

  /* identical to box height */

  color: #121212;
}

.form-check-radio {
  padding-left: 0px !important;
  position: relative;
}

.form-check-radio input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-check-radio label {
  position: relative;
  cursor: pointer;
  width: 100%;
}

.form-check-radio label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1.5px solid #d1d1d1;
  border-radius: 50%;
  padding: 10px;
  display: inline-block;
  position: absolute;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 11px;
  left: 0px;
  top: -3px;
}

.form-check-radio input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0px;
  width: 23px;
  height: 23px;
  border: solid var(--primary-color);
  border-width: 6px;
  transform: translateY(-64%);
  border-radius: 50%;
  background: #ffffff;
  padding: 5px;
}

.form-check-radio.form-check-box label:before {
  content: "";
  top: -2px;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1.5px solid #d1d1d1;
  border-radius: 26% !important;
  padding: 10px;
  display: inline-block;
  position: absolute;
  vertical-align: middle;
  cursor: pointer;
  left: 0px;
}

.form-check-radio.form-check-box input:checked+label:after {
  content: "L";
  font-size: 18px;
  font-weight: 600;
  colorz: var(--primary-color);
  display: block;
  position: absolute;
  top: 9px;
  left: 0px;
  width: 23px;
  height: 23px;
  border: none;
  border-width: 0px;
  transform: translateY(-61%) rotate(37deg) scaleX(-1);
  border-radius: 26%;
  background: transparent;
  padding: 5px;
}

/* slider */
.MuiSlider-rail {
  background-color: #d1d1d1 !important;
  opacity: 1 !important;
}

.MuiSlider-track {
  background: var(--linear-gradient);
  border-color: var(--primary-color) !important;
}

.MuiSlider-thumb {
  background: #ffffff !important;
  border: 1px solid #d1d1d1 !important;
  box-shadow: 0px 4px 4px rgba(171, 171, 171, 0.25) !important;
}

.price-input-grid {
  display: grid;
  grid-template-columns: 46% 8% 46% !important;
  grid-template-rows: auto;
  padding: 0 !important;
  grid-template-areas: "pil pim pir";
}

.price-input-left {
  grid-area: pil;
  display: flex;
  flex-direction: column;
}

.price-input-left span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 2px;
  color: #000000;
}

.price-input-left input {
  background: #f9f9f9;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  height: 42px;
}

.price-input-right input {
  background: #f9f9f9;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  height: 42px;
}

.price-input-middle span {
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 16px;
  margin-bottom: 2px;
  color: #000000;
  display: block;
  margin-top: 15px;
}

.price-input-right {
  grid-area: pir;
  display: flex;
  flex-direction: column;
}

.price-input-right span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 2px;
  color: #000000;
}

.price-input-middle {
  grid-area: pim;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Otp Boxes */

div.otp-boxes>div {
  justify-content: space-evenly;
}

div.otp-boxes>div>div>input {
  width: 54px !important;
  height: 64px;
  font-size: 37px;
  border: 1px solid #797979;
  border-radius: 8px;
}

div.otp-boxes>div>div>input:focus-visible {
  border: 2px solid #fc3c8b;
  border-radius: 8px;
  background: #ffffff;
  outline: none;
}

.btn-primary .spinner-border {
  margin-right: 10px;
}

/* Filter */

.account-nav-main {
  display: flex;
  flex-direction: column;
}

.account-nav-parent {
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 9px;
  /* flex-grow: 1; */
  padding: 20px 47px;
  margin-top: 53px;
}

.account-nav-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;

  color: #ff0055;
}

.account-nav-subheading {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  color: #121212;
}

.account-nav-sub {
  margin-top: 22px;
  margin-bottom: 40px;
}

.account-nav-category-list {
  margin-top: 10px;
  margin-bottom: 10px;
}

div.account-nav-category-list>div.row {
  padding: 6px 0px;
}

.account-nav-counter-column {
  display: flex;
  align-items: center;
  justify-content: center !important;
}

.account-navcategory-counter {
  background: #fff5f9;
  border-radius: 9px;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #ff0055;
  display: block;
  padding: 4px 8px;
  width: 38px;
  height: 21px;
}

.account-nav-category-list>a {
  text-decoration: none !important;
  margin: 10px 0px;
  display: block;
}

.subnav-text {
  padding-left: 0px !important;
  margin-top: 1px;
}

.subnav-text>span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  /* identical to box height */

  color: #919191;
}

.subnav-text.active>span {
  font-weight: 500;

  background: var(--linear-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

/* My Account */

.account-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8f8f8;
  position: relative;
  margin-top: 19px;
  margin-bottom: 53px;
  padding-top: 96px;
  border-radius: 8px;
}

.account-hero .row {
  /* width: 80%; */
  bottom: -53px;
  background-color: #fff !important;
}

.account-general {
  margin-top: 19px;
}

div.account-hero>div.row {
  width: 100%;

  height: 100%;
  overflow: hidden;
  position: relative;
  margin: 0px;
}

.account-photo {
  border: 8px solid #ffffff;
  border-radius: 91.5px;
  width: 153px;
  height: 153px;
  overflow: hidden;
  position: absolute;
  bottom: -59px;
  left: 27px;
}

.account-photo>img {
  width: 100%;
  height: 100%;
}

.account-name {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 139%;

  /* or 25px */

  color: #121212;
}

.account-edit-btn {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  /* identical to box height */

  color: #000;
  background: #fff;
  padding: 10px 9px !important;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
}

.account-edit-btn:hover {
  border: 1px solid var(--primary-color) !important;
  background: var(--primary-color) !important;
}

.account-edit-btn:focus {
  box-shadow: none !important;
  border: 1px solid var(--primary-color) !important;
  background: var(--primary-color) !important;
}

.profile-block {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
}

.profile-block .moreinfo {
  display: flex;
  align-items: start;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
}

.profile-block .moreinfo>div {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-right: 20px;
}

.profile-block .moreinfo>div>span {
  padding-left: 10px !important;
}

.profile-block-child {
  position: relative;
  left: -5%;
}

.profile-ql {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.shape1-acc {
  top: -3%;
  left: -14px;
}

.shape1-acc img {
  width: 46px;
}

.shape2-acc {
  top: 15%;
  left: 195px;
}

.shape2-acc img {
  width: 25px;
}

.shape3-acc {
  bottom: -21px;
  left: 168px;
}

.shape3-acc img {
  width: 82px;
}

.acoount-sub-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 139%;

  /* identical to box height, or 25px */

  color: #121212;
  margin: 16px 0px;
  display: block;
}

/* Quick link grid */
.ql-parent {
  display: grid;
  grid-template-columns: 100% !important;
  grid-template-rows: auto;
  padding: 13px 12px;
  grid-template-areas:
    "ql-icon"
    "ql-name"
    "ql-para";
  background: #ffffff;
  border: 1px solid #cbcbcb;
  border-radius: 9px;
  text-decoration: none !important;
}

.ql-icon-sec {
  grid-area: ql-icon;
  display: flex;
  justify-content: center;
  height: 42px;
  max-height: 42px;
}

.ql-name-sec {
  grid-area: ql-name;
  display: flex;
  flex-direction: row-reverse;
  margin: 10px 0px;
  justify-content: center;
  align-items: center;
}

.ql-para-se {
  grid-area: ql-para;
  display: flex;
  justify-content: center;
}

.ql-name-sec-h {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 139%;

  /* or 25px */

  color: #121212;
}

.ql-name-sec-t {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 108%;
  height: 19.45px;
  color: #c4c4c4;
  margin-right: 6px;
  position: relative;
  top: 1px;
}

.ql-name-sec-p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 139%;

  /* or 19px */

  color: #c4c4c4;
}

.ql-parent:hover {
  background: #fff;
  border: 1px solid var(--primary-color);
}

.ql-parent:hover .ql-name-sec-p {
  color: var(--primary-color);
}

.ql-parent:hover .ql-name-sec-t {
  color: var(--primary-color);
}

.othergrid-parent {
  display: grid;
  grid-template-columns: 20% 80% !important;
  grid-template-rows: auto;
  padding: 13px 12px;
  grid-template-areas: "og-icon og-name ";
  background: #ffffff;
  border: 1px solid #cbcbcb;
  border-radius: 9px;
  text-decoration: none !important;
}

.og-icon-sec {
  grid-area: og-icon;
}

.og-icon-sec svg {
  width: 24px;
  height: auto;
}

.og-name-sec {
  grid-area: og-name;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.othergrid-parent:hover {
  background: #fff;
  border: 1px solid var(--primary-color);
}

/* order list grid */

.order-parent {
  display: grid;
  grid-template-columns: 50% 50% !important;
  grid-template-rows: auto;
  padding: 42px;
  grid-template-areas:
    "order-area-id  order-area-total"
    "order-area-plist order-area-plist"
    "order-area-status order-area-button"
    "order-area-date order-area-button";
  background: #ffffff;
  border: 1px solid #cbcbcb;
  border-radius: 9px;
  margin-bottom: 18px;
}

.order-id {
  grid-area: order-area-id;

  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  /* FG */

  color: #cbcbcb;

  display: flex;
  flex-direction: column;
}

.order-date {
  grid-area: order-area-date;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #cdcdcd;
  display: flex;
  flex-direction: column;
}

.order-total {
  grid-area: order-area-total;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #cdcdcd;
  display: flex;
  justify-content: end;
  align-items: center;
}

.order-button {
  grid-area: order-area-button;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.order-button .btn.ecom-solid {
  margin-top: 0px !important;
  width: fit-content;
  padding-left: 20%;
  padding-right: 20%;
}

.order-status {
  grid-area: order-area-status;

  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  background: var(--linear-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 14px 0px 0px 0px;
}

.order-plist {
  grid-area: order-area-plist;
  margin: 50px 0px 64px 0px;
}

.order-value {
  font-size: 16px;
  line-height: 19px;
  line-height: 19px;
  /* identical to box height */

  color: #5f5f5f !important;
  padding-top: 5px;
}

.outlet-prent-order {
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
}

.outlet-prent-order-name {
  display: flex;
  flex-direction: column;
}

.outlet-prent-order-icon {
  height: 47px;
  width: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dcfff0;
  border-radius: 50%;
  margin-right: 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 0px;
}

.outlet-prent-order-icon img {
  width: 17px;
  padding-right: 3px;
}

.orderdetails-parent {
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 9px;
  padding: 29px;
}

.order-details-address-div {
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  padding: 23px 15px;
  margin-bottom: 15px;
  position: relative;
}

.order-details-address-div-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 13px;
  color: #222222;
}

.order-details-address-div-name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 200.5%;

  /* or 28px */

  color: #000000;
}

.order-details-address-div-address {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 200.5%;

  /* or 28px */

  color: #000000;
}

.order-details-address-div-phone {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 200.5%;

  /* or 28px */

  color: #000000;
}

.order-details-address-div-button {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;

  color: #ff0000;

  background: none;
  padding: 0px !important;
  border: none;
  margin-top: 10px;
  width: fit-content !important;
  position: absolute;
  left: 15px;
  bottom: 15px;
}

.order-details-address-div-button-edt {
  position: absolute;
  top: 6px;
  right: 9px;

  background: var(--linear-gradient);
  width: 41px !important;
  height: 41px !important;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.order-details-address-div-button-edt label {
  width: 41px !important;
  height: 41px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.od-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #121212;
  margin-bottom: 20px;
}

.od-flex {
  display: flex;
  flex-direction: column;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #595959;
  margin-top: 1rem;
}

.order-pres-image-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #222;
  border-radius: 0.5em;
  width: 131px !important;
  height: 195px !important;
  overflow: hidden;
}

.order-pres-image-parent img {
  width: 100%;
  height: auto;
}

.pres-reupload-warning {
  font-weight: 400;
  font-size: 14px;
  line-height: 139.69%;
  /* identical to box height, or 20px */

  color: #ff2323;
}

.od-status {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-top: 13px;
  /* identical to box height */

  color: #121212;
}

.od-list-img img {
  width: 100%;
  height: auto;
}

.od-list-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #0062bd;
}

.od-list-qty {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  position: relative;
  color: #121212;
  display: block;
}

.od-list-qty:before {
  content: "×";
  font-weight: 400;
  font-size: 20px;
  line-height: 16px;
  text-align: right;
  position: absolute;
  top: 50%;
  color: #121212;
  transform: translateY(-57%);
  right: -25px;
}

.od-list-price {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: right;

  color: #121212;
}

.od-list {
  padding-bottom: 10px;
  border-bottom: 1px solid #ededed;
  margin-top: 23px;
}

.od-sum-value {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: right;

  color: #121212;
}

.od-sum-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: right;

  color: #8c8c8c;
}

.od-summ {
  padding-bottom: 13px;
  /* border-bottom: 1px solid black; */
  border-bottom: 1px solid #ededed;
  margin-top: 13px;
}

.od-tot-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: right;

  color: #121212;
}

.od-tot-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: right;

  color: #8c8c8c;
}

.od-tot {
  padding-bottom: 13px;

  margin-top: 13px;
}

.address-create {
  background: #ffffff;
  border: 1px solid #cbcbcb;
  border-radius: 9px;
  padding: 23px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 257px;

  text-decoration: none;
}

.address-create span {
  margin-top: 18px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  /* identical to box height */
  text-align: center;

  color: #5f6368;
}

.addres-grid {
  margin-bottom: 31px;
  height: 257px;
}

.address-boxes {
  height: 100%;
  border: 1px solid #cbcbcb !important;
}

.MuiFormControl-root.MuiTextField-root {
  width: 100%;
  margin: 10px 0px;
}

.address-form-parent {
  border: 1px solid #d1d1d1;
  border-radius: 9px;
  padding: 25px 15px;
}

.address-form {
  padding: 18px;
}

.address-form .add-modal-btns {
  border: none !important;
  margin: 0px !important;
}

.address-form-button {
  background-image: var(--wb-linear-gradient-btn);
  background-image: var(--linear-gradient-btn);

  background-size: auto 140%;
  border: 0px;
  color: var(--btn-stl-clr);
  width: 100%;
  margin-top: 17px;
  border-radius: 9px;
}

.address-form-button:hover {
  background-image: var(--wb-linear-gradient-btn);
  background-image: var(--linear-gradient-btn);
  background-size: auto 140%;
  border: 0px;
  color: var(--btn-stl-clr);
  width: 100%;
  margin-top: 17px;
  border-radius: 9px;
}

.hidden-input {
  display: none !important;
}

/* User Drop Down */

.userDD-Row {
  width: 100%;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  color: #5f5f5f;
}

.userDD-li {
  text-decoration: none;
}

#account-menu ul {
  padding: 10px;
}

#account-menu ul li {
  padding-left: 10px;
  padding-right: 10px;
}

#account-menu ul hr {
  padding-left: 0px;
  padding-right: 0px;
  border-color: #e8e8e8;
  margin-top: 10px;
  margin-bottom: 10px;
}

.userDD-icon {
  padding-right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userDD-icon {
  text-align: center;
}

.userDD-Row.row .col-md-10 {
  display: flex;
  align-items: center;
}

.btn-logout {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 6px 40px;
  gap: 10px;
  width: 100%;

  background: #172026;
  border-radius: 50px;
  margin-top: 7px;
}

/* pagination */

.pagination-parent {
  margin-top: 19px;
  background: #ffffff;
  border: none;
  border-radius: 12px;
  padding: 10px;
}

.pagination-parent>nav>ul {
  justify-content: center;
}

.pagination-parent>nav>ul>li>button:hover {
  background: none;
  color: var(--primary-color);
}

.pagination-parent>nav>ul>li>button.Mui-selected {
  background: none;
  color: var(--primary-color);
}

/* Product Details */

.pd-old-price {}

.pd-disc {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  /* identical to box height */
  margin-left: 7px;
  color: #ff0055;
}

.pd-old-price {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  /* identical to box height */
  text-decoration: line-through;
  color: #595959;
}

.pd-price {
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
  position: relative;
  /* identical to box height */

  color: #000000;
  margin-top: 25px;
  display: block;
  width: fit-content !important;
}

.pd-manufacturer-name {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #595959;
}

.pd-manufacturer-name>span {
  font-weight: 600;
}

.pd-name {
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  text-transform: capitalize;

  /* C1/A */
  color: #151515;
}

.pd-cart-button-grp {
  width: fit-content;
  border: 1px solid #c9c9c9;
  border-radius: 12px;
  padding: 5px 7px;
}

.pd-cart-button-grp .btn-secondary {
  background: #3f4254 !important;
  border-radius: 12px !important;
}

.pd-quantity-header {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #595959;
  display: block;
  margin-top: 13px;
  margin-bottom: 8px;
}

.pd-cart-button-grp>span {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  padding: 0px 16px;
}

.pd-accordians .accordion-header>button {
  border-radius: 0px !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-left: 4px;
  padding-right: 4px;
  /* identical to box height */

  color: #000000 !important;
  background-color: #fff !important;
  box-shadow: none !important;
}

.pd-accordians .accordion-body {
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 0px;
}

.pd-accordians .accordion-button::after {
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  background-attachment: fixed;
  border: 1px solid #a6a6a6;
  border-radius: 50%;
  background-position: center;
  background-size: 0.9rem;
  transform: rotate(360deg);
}

.pd-accordians .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
  transform: rotate(180deg) !important;
}

.pd-accordians .accordion-item {
  border: none;
  border-top: 1px solid #e2e2e2;
}

.pd-accordians {
  margin-top: 23px;
}

.pd-accordian-sh {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  /* identical to box height */

  color: #595959;
}

.dot {
  width: 3.9px;
  height: 3.9px;
  left: 791.72px;
  top: 600.77px;
  background: #797979;
  display: block;
  border-radius: 50%;
  margin: 0px 6px;
}

.pd-accordian-h {
  display: block;
  margin-bottom: -5px;
}

.accordian-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.pd-abh {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;

  /* or 22px */

  color: #222222;
}

.pd-abt {
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;

  /* or 22px */

  color: #222222;
}

.image-gallery-slide .image-gallery-image {
  width: 320px !important;
  object-fit: contain;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  text-align: left !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  background: #f9f9f9 !important;
  border: 1px solid #222222 !important;
  border-radius: 12px !important;
  padding: 5px;
  overflow: hidden;
}

.image-gallery-thumbnail:hover {
  outline: none;
  border: 1px solid #222222 !important;
  border-radius: 12px !important;
  padding: 5px;
  overflow: hidden;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  background-color: #222222 !important;
  width: 37.15px !important;
  height: 37.15px !important;
  padding: 0px !important;
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-gallery-icon>svg {
  height: 100% !important;
  width: 100% !important;
}

.image-gallery-bullets .image-gallery-bullet {
  box-shadow: none !important;
  background-color: black !important;
  border: 1px solid !important;
}

.image-gallery-bullets .image-gallery-bullet.active {
  transform: scale(1.2);

  background: #fff !important;
}

.image-gallery-bullets .image-gallery-bullet:hover {
  background: #fff !important;
}

.image-gallery {
  margin-right: 50px !important;
}

.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
  margin-top: 21px;
}

.image-gallery-icon:hover {
  color: #fff !important;
}

.pd-btn {
  width: 100%;
  border: 2px solid var(--btn-light);

  border-radius: 9px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  margin-top: 17px;
  margin-bottom: 10px;
  color: var(--btn-light) !important;
}

.pd-btn path {
  fill: var(--btn-light) !important;
}

.pd-atc {
  background-image: var(--wb-linear-gradient-btn);
  background-image: var(--linear-gradient-btn);

  background-size: auto 140%;
  border: 2px solid transparent;
  color: var(--btn-stl-clr) !important;
  width: 100%;
  margin-top: 17px;
  border-radius: 9px;
}

.pd-atc :hover {
  background-image: var(--wb-linear-gradient-btn);
  background-image: var(--linear-gradient-btn);

  background-size: auto 140%;
  border: 0px;
  color: var(--btn-stl-clr) !important;

  border-radius: 9px;
}

.pd-btn>img,
.pd-btn>svg {
  margin-right: 10px;
}

.pdetails-btn>svg path {
  fill: var(--btn-stl-clr) !important;
}

.pd-atc>img {
  width: 16px !important;
}

/* cart details */

.cart-details-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  text-decoration: none !important;
  /* C1/A */
  color: #151515;
}

.cart-details-by {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #595959;
}

.cart-details-manu {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  color: #595959;
}

.cart-details-qty {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin-top: 10px;
  color: #595959;
  display: flex;
  align-items: center;
}

.cart-details-item {
  border-bottom: 1px solid #cbcbcb;
  padding-bottom: 20px;
  margin-top: 20px;
}

.cart-details-img {
  width: 85%;
}

.cart-details-subtotal {
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 400;
  font-size: 17px;
  line-height: 26px;

  /* identical to box height */
  text-align: right;

  color: #000000;
}

.cart-details-subtotal span {
  font-weight: 500;
  margin-left: 5px;
  display: inline-block;
}

.os-parent {
  border: 1px solid #cbcbcb;
  border-radius: 9px;
  margin: 10px 10px;
  padding: 10px 17px;
}

.os-numbers {
  text-align: right;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  /* identical to box height */
  text-align: right;

  color: #121212;
}

.os-text {
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #121212;
}

.os-header {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 6px;
  line-height: 21px;
}

.os-gt-parent {
  margin-top: 8px;
  border-top: 1px solid #e1e1e1;
  padding-top: 10px;
}

.os-gt-parent .os-text,
.os-gt-parent .os-numbers {
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;

  /* identical to box height */

  color: #121212 !important;
}

.form-check-label .disc-row {
  margin-left: 24px;
}

.disc-label::before {
  position: absolute !important;
}

.disc-label::after {
  position: absolute !important;
  top: 12px !important;
}

.disc-discountAppOn {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;

  /* or 21px */

  color: #595959;
}

.disc-discountSave {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;

  /* or 21px */

  color: #121212;
}

.disc-discountCode div {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #121212;
  border: 1px dashed #000000;
  border-radius: 4px;
  width: fit-content;
  padding: 5px 10px;
  margin-bottom: 4px;
}

.cpn-ipt-check {
  border: 1px solid #000000;
  border-radius: 9px;
}

.cpn-ipt-check input {
  border: none;
  border-radius: 9px;
  height: 39px;
}

.cpn-ipt-check input:focus {
  box-shadow: none !important;
}

.cpn-ipt-check button {
  border: none;
  border-radius: 9px;

  color: #00dee0;
}

.cpn-ipt-check button:hover {
  border: none;
  border-radius: 9px;
  background-color: #fff !important;
  color: #00dee0;
}

.cpn-ipt-check button:focus {
  box-shadow: none !important;
}

.cpn-exixts {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  /* identical to box height */

  color: #ff4040;
}

.disc-mb {
  margin: 0px;
}

.disc-mf {
  justify-content: space-between !important;
}

.ftr-parent span {
  font-weight: 600;
  font-size: 12px;
  line-height: 139%;

  /* identical to box height, or 17px */

  color: #121212;
}

.ftr-parent div {
  font-weight: 400;
  font-size: 22px;
  line-height: 139%;

  /* or 31px */

  color: #17940c;
}

.dis-apply {
  width: 349px;
}

.distitle {
  font-weight: 500;
  font-size: 14px;

  color: #121212;
  cursor: pointer;
}

.saved-money {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  /* identical to box height */

  color: #17940c;
}

.pl-sp-qty {
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;

  /* identical to box height */

  color: #000000;
  margin-left: 25px;
}

.cart-details-rfc {
  display: flex;
  align-items: center;
  justify-content: end;
}

.cart-details-rfc img {
  transform: rotate(45deg);
}

.disc-address-opt div {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #222222;
}

.disc-address-opt div div {
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;

  /* identical to box height, or 18px */

  color: #008788;
}

#fade-in {
  height: 150px;
  width: 1px;
  opacity: 0;
  transition: all 0.75s ease;
}

#fade-in.show {
  opacity: 1;
  height: 150px;
  width: 500px;
}

.modal-header-cust {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;

  /* identical to box height, or 27px */

  color: #222222;
}

.modalBanck-btn {
  cursor: pointer;
  padding-right: 15px;
}

.modalBanck-btn>img {
  transform: rotate(180deg);
}

.getmyloc {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  margin: 15px 0px;
  /* identical to box height, or 24px */

  text-transform: uppercase;

  color: #008788;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.getmyloc img {
  padding-right: 10px;
}

.add-modal-btns {
  display: flex;
  border-top: 1px solid #acacac;
  padding-top: 13px;
  margin-top: 30px;
}

.add-modal-btns button {
  flex-grow: 1;
  width: auto !important;
  margin: 10px;
}

.selected-adddd div {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 124.69% !important;

  /* or 15px */

  color: #222222 !important;
}

.disc-address-opt {
  position: relative;
}

.select-address-btn {
  border: none;
  background: #fff;
  color: #008788;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 4px;
  padding-left: 0px;
}

.change-address-btn {
  border: none;
  background: #fff;
  color: #008788;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 4px;
  padding-left: 0px;

  top: 2px;
  right: 13px;
  position: absolute;
}

.os-parent-pymentmode {
  margin: 10px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 139%;

  /* or 19px */

  color: #121212;
}

.os-parent-pymentmode span {
  font-weight: 500;
}

.os-parent-button {
  margin: 0px 10px;
}

.outlet-parent {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
}

.outlet-parent>img {
  margin-right: 10px;
  margin-left: 7px;
}

.outlet-parent>div {
  flex-grow: 1;

  padding-right: 12px;
}

.outlet-parent>div>div {
  margin: 0px !important;
}

.outlet-parent>div>div>div::before {
  border: none !important;
}

.login-btn {
  border: 1px solid #00d8da;
  filter: drop-shadow(0px 3px 4px rgba(210, 210, 210, 0.25));
  border-radius: 6px;
  padding: 7px 26px;
  cursor: pointer;
  display: flex;
  width: 111px;
  justify-content: center;
  align-items: center;
}

.login-btn img {
  width: 25px;
}

.login-btn-grp {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.login-or {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-or span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #222222;
  position: relative;
  width: 100%;
  display: block;
  margin: 26px 0px;
}

.login-or>span::before {
  width: 45%;
  border-top: 1px solid #00dee0;
  content: "";
  /* height: 24px; */
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
}

.login-or>span::after {
  width: 45%;
  border-top: 1px solid #00dee0;
  content: "";
  /* height: 24px; */
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
}

.header-1-login {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;

  color: #222222;

  margin-bottom: 16px;
  display: block;
}

.login-grid {
  display: flex;
  justify-content: center;
}

.login-box {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-shadow: 0px 2px 6px rgba(221, 221, 221, 0.25);
  border-radius: 10px;
  padding: 28px 24px;
}

.tandc {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #595959;
  display: block;
  margin-top: 19px;
  margin-bottom: 10px;
}

.tandc a {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-color);
  text-decoration: none;
}

.gethelp {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;

  color: #595959;
  display: block;
}

.gethelp>span {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: var(--primary-color);
}

.gethelp .HelpLink {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: var(--primary-color);
  text-decoration: none;
}

.verify-1 {
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  margin-top: 5px;
  margin-bottom: 12px;
  color: #121212;
}

.verify-2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  /* identical to box height */

  color: #fc3c75;
}

.verify-3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  /* identical to box height */
  margin: 10px 0px;
  color: #121212;
}

.verify-4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  color: #ff0055;
}

.verify-5 {
  display: flex;
  align-items: end;
  justify-content: end;
}

.verify-5>button {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ff0055;
  padding: 0px;
  border: none;
  background: white;
}

.verify-5>button:disabled,
.verify-5>button[disabled] {
  color: #939393;
}

.verify-5>span {
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  display: block;
  margin-left: 7px;
  color: #939393;
}

.phone-code {
  width: 100% !important;
  border: none !important;
}

.phone-code-dropDown {
  border: none !important;
  background: none !important;
}

.phone-no-grp {
  border: none;
  height: 100%;
  width: 100%;
  outline: none !important;
  margin: 0px;
  border-bottom: 1px solid #bababa;
  padding-bottom: 4px;
}

.phoneNumber-login {
  width: 100%;
  height: 100%;
  border: none;
  outline: none !important;
}

.phone-no-grp>.col-md-4 {
  padding-left: 0px !important;
}

.password-block {
  width: 100%;
}

.from-cust-blocks {
  padding: 5px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #595959;
  background: #eeeeee;
  border-radius: 5px;
}

.from-cust-blocks.active {
  color: #fff;
  background: #146aff;
}

.validator-grp {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.csu-subheading {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #222222;
}

.csu-subheading span {
  color: #999999;
}

.csu-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  color: #595959;
}

.csu-optional {
  border: 1px solid #eaeaea;
  border-radius: 14px;
  margin-left: 28px;
  padding: 20px 22px;
}

.order-placed-heading {
  font-weight: 500;
  font-size: 24px;
  line-height: 170%;

  /* identical to box height, or 41px */

  color: #000000;
}

.order-placed-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;

  /* or 27px */
  text-align: center;

  color: #000000;
}

.gender>div {
  width: 100% !important;
}

.img-container-edit-profile {
  position: relative;
  width: 278px;
}

.img-container-edit-profile>img {
  border-radius: 22px;
  width: 278px;
}

.img-container-edit-profile>div {
  position: absolute;
  top: 10px;
  right: 10px;
}

.verify-profile {
  display: flex;
  align-items: start;
  padding: 0px;
  justify-content: start;
  flex-direction: column;
}

.verify-profile>button {
  color: #000;
  cursor: pointer;
  padding: 0px;
  font-weight: 400;
  /* line-height: 19px; */
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #fff !important;
  border: none !important;
}

.mobile-header {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.4375em;
  display: block;
  margin-bottom: 4px;
}

.order-placed-no {
  font-weight: 500;
  font-size: 16px;
  line-height: 170%;

  /* or 27px */

  color: #000000;
}

.order-placed-date {
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;

  /* identical to box height, or 24px */
  text-align: center;

  color: #000000;
}

.noClick {
  cursor: no-drop;
}

.map-filler {
  position: relative;
  overflow: hidden;
}

.map-container-add {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 0px;
  background-color: #fff;
}

.search-results-map {
  top: 36px;
}

.search-item-name-map {
  font-size: 15px;
  /* padding: 11px 0px; */
  display: block;
  margin: 19px 5px;
  cursor: pointer;
}

.wb-2 {
  display: -webkit-box;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.download-showcase .container {
  position: relative;
}

.fl-loader {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-20%, -50%);
}

.prduct-list-parent {
  position: relative;
  background: #ffffff;
  border-radius: 4px;
  padding: var(--bs-gutter-x, 1.3rem) var(--bs-gutter-x, 1.7rem);
  margin-top: 27px;
}

.outlet-parent .MuiAutocomplete-root {
  margin-top: 10px;
}

.select-store {
  position: absolute;
  top: 4px;
  left: 39px;
  font-size: 11px;
}

.right-border {
  position: absolute;
  top: 50%;
  right: 0px;
  font-size: 10px;
  border-right: 1px solid #d8d8d8;
  width: 1px;
  height: 23px;
  transform: translateY(-50%);
}

.header-1 span {
  padding-right: 7px;
}

.pc-radio .form-check-radio input:checked+label:after {
  transform: translateY(-51%) !important;
}

.user-profile-logout {
  display: none;
}

.log-main {
  max-height: 70px;
  height: 70px !important;
}

.screenshot-home {
  width: 100% !important;
  max-width: 100% !important;
}

.upload-pres {
  position: relative;
}

.upload-back {
  display: flex;
  justify-content: end;
}

.upload-back img {
  height: 140px;
}

.filepond--drip {
  background-color: #f3f7ff !important;
  border: 1px dashed #cacaca !important;
  border-radius: 5px;
  opacity: 1 !important;
}

.filepond--credits {
  display: none !important;
}

.empty .filepond--root .filepond--drop-label {
  min-height: 4.75em;
  height: 200px;
}

.filepond--list {
  display: flex;
  flex-direction: row;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  justify-content: center;
}

.filepond--item {
  display: block;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  transform: none !important;
  width: 175px !important;
  height: 261px !important;
}

.bel-upload {
  font-size: 12px !important;
}

.left-menu {
  width: 0px;
  overflow: hidden;
  transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.left-menu.open {
  width: 50%;
}

.otc-product {
  color: #fff;

  background: #0259e6;
  border-radius: 21px;
  color: #fff;
  padding: 3px 9px;
  display: inline-block;
  margin-top: 12px;
  font-weight: 600;
  font-size: 14px;
}

.filepond--wrapper,
.filepond--drop-label,
.filepond--drop-label>label {
  cursor: pointer !important;
}

.MuiStepIcon-root.Mui-completed {
  color: #784af4 !important;
}

.MuiStepIcon-root.Mui-active {
  color: #784af4 !important;
}

.order-pres-parent {
  display: flex;
}

.order-pres-parent .order-pres-image-parent {
  margin: 5px 5px;
}

.cancelled-step .MuiStepIcon-root.Mui-active {
  color: #dc3545 !important;
}

.header-app-section {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 33px;
  /* identical to box height, or 103% */

  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.text-app-section {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  margin-top: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #212529;
}

.text-app-section-bottom {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.22em;
  text-transform: uppercase;
  margin-top: 19px;
  color: #ffffff;
}

.download-showcase .row {
  background: transparent;
  border-radius: 10px;
  flex-direction: row-reverse;
}

.row-app-section:before {
  content: "";
  position: absolute;
  width: 600%;
  height: 691%;
  top: -50%;
  left: -102%;
  z-index: -1;
  background: var(--primary-color);
  transform: rotate(30deg);
  background-image: url("/src/static/pattern.svg");
  background-repeat: repeat, repeat;
}

.row-app-section {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.row-app-section2 {
  position: relative;
  padding: 95px 0px 33px 0px;
}

.row-app-section2 .screenshot-home-app {
  position: absolute;
  width: 236px;
  bottom: 0px;
  border-bottom-right-radius: 0px;
  left: 105px;
  padding: 0px !important;
}

.app-buttons-inner {
  display: flex;
  flex-direction: column;
}

.top-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  display: block;
  margin-bottom: 3px;
  /* identical to box height, or 200% */

  color: var(--btn-stl-clr);
}

.bottom-text {
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  color: var(--btn-stl-clr);
}

.app-button-center {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.app-btton {
  background: #212529;
  border-radius: 12px;
  text-decoration: none;
  padding: 9px 24px;
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.app-btton svg {
  margin-right: 22px;
}

.app-btton svg path {
  fill: var(--btn-stl-clr);
}

.download-showcase {
  padding: 0px 0px;
  margin-top: 74px;
  padding-bottom: 30px;
}

.para-topic p {
  text-align: justify;
}

.header-3-footer {
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  /* identical to box height, or 114% */

  color: #121212;
}

.qlinks li div.row {
  text-decoration: none !important;
  margin: 23px 0px;
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  /* identical to box height, or 171% */

  color: #212529;
}

footer .app-buttons-inner {
  display: flex;
  flex-direction: column;
}

footer .top-text {
  font-weight: 400;
  font-size: 7.35872px;
  line-height: 15px;
  /* identical to box height, or 200% */

  color: #fff;
}

footer .bottom-text {
  font-weight: 900;
  font-size: 12.2645px;
  line-height: 15px;
  /* identical to box height, or 120% */

  color: #fff;
}

footer .app-button-center {
  display: flex;
  justify-content: left;
  margin-top: 24px;
}

footer .app-btton {
  background: #212529;
  border-radius: 12px;
  text-decoration: none;
  padding: 9px 24px;
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

footer .app-btton.ms-0 {
  margin-left: 0px !important;
  margin-top: 0px !important;
  margin-right: 2px !important;
}

footer .app-btton.me-0 {
  margin-left: 2px !important;
  margin-right: 0px !important;
  margin-top: 0px !important;
}

footer .app-btton img {
  margin-right: 14px;
  width: 25px;
}

footer .download-showcase {
  padding: 0px 0px;
}

.add-ftr {
  text-align: justify;
}

.ol-sm-h {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  display: block;

  /* FG */

  color: #cbcbcb;
}

.ol-sm-name {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  margin-bottom: 22px;
  display: inline-block;
  color: #5f5f5f;
}

.ol-sm-t {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  color: #5f5f5f;
}

.ol-image {
  width: 100px;
  height: auto;
}

.order-pl-block {
  border: 1px solid #cbcbcb;
  border-radius: 10px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.m-r-s0 {
  margin-right: 0.1rem;
}

.m-l-s0 {
  margin-left: 0.1rem;
}

.gray-badge-productlist {
  background: #eff0f1;
  border-radius: 10px;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  position: relative;

  color: #9fa1a3;
}

.bw-linwe {
  position: relative;
  margin-top: 22px;
}

.bw-linwe::before {
  content: " ";
  width: 100%;
  height: 0px;
  opacity: 0.5;
  border: 1px solid #cbcbcb;
  /* left: -40.5px; */
  top: 50%;
  transform: translatey(-50%);
  position: absolute;
}

.order-total-btn {
  background: #f5f5f6;
  border-radius: 10px;
  width: fit-content;
  padding: 10px 8%;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.order-total-btn span {
  font-weight: 700;
}

.pd-type {
  background: #0259e6;
  border-radius: 21px;
  color: #fff;
  padding: 3px 9px;
  display: inline-block;
  margin-top: 12px;
  font-weight: 600;
  font-size: 14px;
}

.category-banner {
  width: 100%;
  border-radius: 11.7544px;
}

.home-hero .container {
  z-index: 1;
  position: relative;
}

.subcategories-section {
  background: #fff;
  padding: 28px 28px 38px 28px;
  border-radius: 4px;
}

.subcategories-section .gci {
  border-color: transparent !important;
  border-radius: 8px;
}

.subcategories-section .gci .lowerContent {
  text-align: center;
}

.subcategories-section .gci .item-name {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;

  text-overflow: ellipsis;
  /* enables ellipsis */
  white-space: nowrap;
  /* keeps the text in a single line */
  overflow: hidden;
  /* keeps the element from overflowing its parent */
}

.slick-track {
  margin: 0px !important;
}

div.slick-slide>div {
  margin: 0px !important;
  width: 100% !important;
}

.allProducts {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 21px;
  /* identical to box height, or 88% */

  display: flex;
  align-items: center;
  text-transform: capitalize;
  margin-top: 32px;
  margin-bottom: 8px;
  color: #212529;
}

/* SLICK SLIDER --START */

.banner-container .slick-slide {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.banner-container .slick-slide img {
  border-radius: 8px;
  transform: scale(0.95);
}

.banner-container .slick-slide.slick-active {
  filter: none;
  -webkit-filter: none;
  transform: scale(1.05);
  transition: transform 1s;
}

.banner-container .slick-center {
  /* color: #e67e22;
  opacity: 1;
  -ms-transform: scale(1.08);
  transform: scale(1.08); */
}

.slick-container .slick-slider>.slick-arrow {
  background: rgb(23 32 38 / 50%) !important;
  border: none;
  box-shadow: 0 4px 8px 0 rgb(55 48 54 / 20%);
  border-radius: 0.5rem;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 45px;
  line-height: 0;
  outline: none;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 38px;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  -moz-transition: visibility 0.25s ease, opacity 0.45s ease;
  -webkit-transition: visibility 0.25s ease, opacity 0.45s ease;
  -o-transition: visibility 0.25s ease, opacity 0.45s ease;
  -ms-transition: visibility 0.25s ease, opacity 0.45s ease;
}

.slick-container .slick-slider>.slick-arrow:first-child {}

.slick-container:hover .slick-slider>.slick-arrow {
  opacity: 1;
  visibility: visible;
}

.slick-container:hover .slick-slider>.slick-arrow:first-child {}

.slick-container .slick-prev:before {
  top: 0px !important;
  left: 0px !important;
  content: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon-icon-5Yc"><polyline points="15 18 9 12 15 6"></polyline></svg>');
}

.slick-container .slick-next:before {
  top: 0px !important;
  left: 0px !important;
  content: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon-icon-5Yc"><polyline points="9 18 15 12 9 6"></polyline></svg>');
}

/* SLICK SLIDER --START */

.subcat-head {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 21px;
  /* identical to box height, or 105% */

  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #212529;
  margin-bottom: 17px;
}

.brand-hone .container {
  background-color: var(--hero-back) !important;
  padding-right: var(--bs-gutter-x, 2rem);
  padding-left: var(--bs-gutter-x, 2rem);
  padding-top: 40px;
  padding-bottom: 40px;
}

.brand-hone .lowerContent {
  display: none !important;
}

section.brand-hone {
  padding-top: 0px;
}

.home-head {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #212529;
}

.home-head span {
  font-weight: 500;
  padding-left: 6px;
}

.brand-sec-margin .mobile-grid {
  margin-top: 19px;
}

.upload-pres-sec .container {
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  padding: 49px 33px 49px 33px;
}

/* .cat-widget .item-count{
 display: none !important;
} */

.cat-widget .item-name {
  text-align: center !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 40px !important;
  /* identical to box height, or 200% */

  color: #000000 !important;
}

.brand-sec-margin-featured {
  padding-top: 0px !important;
}

.nav-parent {
  background-color: var(--primary-color);
  padding: 25px 0px;
}

.cust-padding-for-head {
  padding-top: 200px;
}

.hover-drop {
  position: relative;
}

.hover-drop:hover>.sub-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  z-index: 1;
}

.hover-drop .sub-menu {
  position: absolute;
  top: 100%;
  background: #fff;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 10%);
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 10%);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  padding: 5px 15px;
  min-width: 280px;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top;
  transform-origin: top;
  opacity: 0;
  visibility: hidden;
  z-index: -100;
}

.hover-drop img {
  width: 20px;
  margin-right: 10px;
}

.hover-drop .sub-menu {
  list-style: none;
}

.hover-drop .sub-menu {
  min-width: 155px;
}

.hover-drop .sub-menu li.menu-item {
  margin: 10px 0px;
  cursor: pointer;
}

.hover-drop a {
  cursor: pointer;
}

.map-create {
  position: absolute;
  top: 0px;
  width: 100%;
  background: #fff;
  left: 0px;
}

.focusedSearchParent {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #000000ad !important;
  top: 0px;
  left: 0px;
  z-index: 6;
}

.focusedSearchParent .header-main {
  background: #fff0 !important;
}

.focusedSearch {
  flex-direction: column;
}

.focusedSearchL {
  align-items: start;
}

.focusedSearchR {
  align-items: end;
}

.focusedSearchR .foc-wrapper {
  width: 79% !important;
}

.focusedSearch .info-box-wrapper {
  position: relative;
}

.focusedSearch .info-box {
  -webkit-user-select: none;
  user-select: none;

  background: #f4f4f4;
  border-radius: 10px;

  font-size: 14px;

  padding: 10px 11px 10px 10px;

  margin: 17px 12px 0px 12px;
}

.focused-input {
  background: #f4f4f4;
  border: 1px solid #f4f4f4;
  border-radius: 43px;
  height: 45.25px;
  margin: 0px 12px;
  position: relative;
}

.foc-wrapper {
  width: 62%;
}

.dec-text {
  display: flex;
  flex-direction: column;
}

.dete-m-loc {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
  cursor: pointer;
}

.dec-text .dec-text-u {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* tEXT */

  color: #222222;
}

.dec-text .dec-text-l {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  /* text */

  color: #b5b5c3;
}

.dec-icn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 11px;
}

.outlet-widget {
  display: flex;
  margin-bottom: 10px;

  background: #ffffff;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
}

.outlets-header-title {
  padding: 10px 0px;
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #222222;
}

.outlet-widget-inc {
  width: 80px;
  min-width: 80px;
  height: 120px;
  left: 3px;
  top: 3px;

  background: var(--primary-color);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  flex-direction: column;
}

.outlet-widget-inc span {
  display: block;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  margin-top: 7px;
  color: #ffffff;
}

.outlet-widget-text-1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  margin-top: 10px;
  /* tEXT */

  color: #222222;
}

.outlet-widget-text-2 {
  display: flex;
  margin: 10px 0px;
}

.outlet-widget-text-2-1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;

  color: #7cb940;
}

.outlet-widget-text-2-2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 9px;

  color: #7cb940;
}

.outlet-widget-text-3 {
  display: flex;
}

.outlet-widget-text-2-2 {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.outlet-widget-text-3-1 {
  margin-right: 7px;
}

.no-scroll {
  overflow: hidden;
}

.out-list {
  max-height: 420px;
  overflow-y: scroll;
}

.out-list::-webkit-scrollbar {
  width: 8px;
}

.out-list::-webkit-scrollbar-track {
  background-color: #fff0;
  border-radius: 100px;
}

.out-list::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 100px;
}

.search-list div {
  padding: 10px 9px;
  border-bottom: 1px solid #e6e7e7;
}

.search-list div:last-child {
  padding: 10px 9px;
  border-bottom: none !important;
}

.empty-search {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.out-sel-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 19px;
  /* identical to box height, or 81% */

  display: flex;
  align-items: center;

  /* text 2 */

  color: #3f4254;
}

.out-btn-picker {
  background: #000000;
  border-radius: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  padding: 10px 20px;
  margin: 21px 0px;
  display: block;
}

.modal-90w {
  max-width: 90vw !important;
}

.out-sel-t2-h {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  /* fg */

  color: #5f5f5f;
}

.out-sel-t1 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* tEXT */

  color: #222222;
}

.out-sel-days {
  display: flex;
}

.out-week-btn {
  border-radius: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 0.9rem;
  /* identical to box height */

  /* fg */
  border: 1px solid #dc3545;

  color: #dc3545;

  /* identical to box height */

  padding: 2px 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0px 2px;

  width: 36px;
  min-width: 36px;
}

.out-week-btn-active {
  border: 1px solid #5f5f5f;

  color: #5f5f5f;
}

.out-week-btn:first-child {
  margin-left: 0px;
}

.out-week-btn:last-child {
  margin-right: 0px;
}

.out-sel-t2-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  /* tEXT */

  color: #222222;
}

.phone-icn {
  background: #f5f5f5;
  border-radius: 9.12px;
  padding: 12px;
}

.out-contact {
  margin-top: 18px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.outlet-list {
  background: #ffffff;
  box-shadow: 0px 12.0229px 24.0458px #f0f7fa;
  border-radius: 8px;
  padding: 29px 20px;
  width: 368px;
  min-width: 368px;
  margin-bottom: 17px;
  margin-right: 14px;
  margin-left: 14px;
}

.ol-pr {
  position: relative;

  margin-bottom: 332px;
}

.selout-btn {
  background: var(--linear-gradient-btn);
  border-radius: 50px;
  color: var(--btn-stl-clr);
  text-decoration: none;
  padding: 10px 25px;
  cursor: pointer;
  border: none;
}

.selout-btn.disabled {
  background: #f5f5f5;
  border-radius: 50px;
  color: #222;
  text-decoration: none;
  padding: 10px 25px;
  cursor: default;
}

.selout-btn:hover,
.selout-btn:focus {
  color: var(--btn-stl-clr);
  text-decoration: none;
}

.selout-btn.disabled:hover,
.selout-btn.disabled:focus {
  color: #222;
  text-decoration: none;
}

.out-sel-t2 {
  display: flex;
  margin: 10px 0px;
}

.out-sel-t21 {
  margin-right: 10px;
}

.out-sel-t22 {
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  /* identical to box height */

  color: #5f5f5f;
}

.out-sel-id {
  font-weight: 700;
  font-size: 11.2px;
  line-height: 13px;
  color: #5f5f5f;
  text-align: center;
  width: 100%;
  margin-left: 2px;
  margin-top: 5px;
}

.outlet-listparent {
  height: 510px;
  overflow-y: scroll;
}

.outlet-listparent2 {
  height: fit-content !important;
  overflow-x: scroll;
  display: flex;
}

.outlet-listparent::-webkit-scrollbar {
  width: 8px;
}

.outlet-listparent::-webkit-scrollbar-track {
  background-color: #fff0;
  border-radius: 100px;
}

.outlet-listparent::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 100px;
}

.wish-prod-det {
  border: 0.5px solid #f5f8fa;
  width: 36px;
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.disc-price-pd {
  position: absolute;
  background: #ff7020;
  border-radius: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  color: #ffffff;

  top: -7px;
  right: -65px;
  padding: 2px 4px;
}

.cartnew {
  position: relative;
  text-decoration: none;
  -webkit-transition: width 0.35s;
  -o-transition: width 0.35s;
  transition: width 0.35s;
}

.cartnew.opendrop .cart-drop {
  opacity: 1;
  visibility: visible;
}

.cartnew .cart-drop::before {
  content: " ";
  background-color: #ffffff00;
  width: 399px;
  height: 20px;
  position: relative;
  top: -9px;
}

.cart-drop {
  position: absolute;
  top: 35px;
  right: 0px;
  width: 399px;
  height: 413px;
  background: #ffffff;
  box-shadow: 0px 15px 30px rgb(0 0 0 / 15%);
  border-radius: 20px;
  z-index: 1;
  margin-top: 12px;
  display: flex;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.35s;
  -o-transition: 0.35s;
  transition: 0.35s;
  flex-direction: column;
}

.cart-drop .cd-head {
  padding: 0.7rem 1rem;

  border-bottom: 1px solid #e9ecef;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-drop .cd-body {
  flex: 1 1 auto;
  padding: 1rem;
  max-height: 364px;
  overflow-y: scroll;
}

.cart-drop .cd-body::-webkit-scrollbar {
  width: 8px;
}

.cart-drop .cd-body::-webkit-scrollbar-track {
  background-color: #fff0;
  border-radius: 100px;
}

.cart-drop .cd-body::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 100px;
}

.cart-drop .cd-foot {
  padding: 1rem;
  border-top: 1px solid #e9ecef;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cd-text-rak {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cd-prodImg {
  width: 100%;
}

.cd-prodName {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 33px;
  text-transform: capitalize;
  color: #151515;
  margin-bottom: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cd-prodQty,
.cd-prodValue {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  /* fg */

  color: #5f5f5f;
  margin-bottom: 10px;
}

.cd-head span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  text-transform: capitalize;

  color: #000000;
}

.counter-inner {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;

  color: var(--primary-color);
}

.cart-ind-text {
  display: flex;
  flex-direction: column;
  margin-left: 6px;
}

.cart-ind-text span:first-child {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height, or 131% */

  display: flex;
  align-items: center;

  color: #ffffff;
  display: none;
}

.remove-cart-drpdwn {
  cursor: pointer;
}

.filter-sub.category .accordion-button {
  padding: 0px;
  border: none;
  box-shadow: none !important;
  background-color: #fff0;
}

.filter-sub.category .accordion-item {
  border: none;
  box-shadow: none !important;
}

.cat-filter-header {
  padding: 0px 0px 17px 0px;
}

.cat-filter-header a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #121212;
  display: block;
  text-decoration: none !important;
}

.cat-filter-header.active a {
  background: var(--linear-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.cat-filtrrr {
  margin-bottom: 0px;
}

.cat-filtrrr .accordion-body {
  padding: 0px !important;
}

.cat-filtrrr .accordion-body ul {
  list-style: none;
  padding-left: 0.5rem;
  margin-bottom: 0px;
}

.subcat-filter-header {
  padding: 0px 0px 17px 0px;
}

.subcat-filter-header a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #121212;
  display: block;
  text-decoration: none !important;
}

.subcat-filter-header.active a {
  background: var(--linear-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.footerInfo {
  display: inline !important;
}

.countryCode-login {
  flex: 1 !important;
}

.countryCode-login .MuiInput-root::before {
  border-bottom: none !important;
}

.countryCode-login .MuiFormControl-root {
  margin-bottom: 3px !important;
}

.countryCode-login .MuiInput-root:after {
  border-bottom: none !important;
}

.countryCOdeparent {
  display: flex;
  align-items: center;
  width: 100%;
}

.countryCOdeparent img {
  margin-right: 10px;

  margin-top: 8px;
}

.cat-cont {
  background: #fff;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  padding: 16px 33px 0px 33px;
}

.cat-cont::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 4px;
  background-color: var(--primary-color);
  top: 0px;
  left: 0px;
}

.cat-cont .slick-track {
  padding-bottom: 15px !important;
}

.cat-cont .mobile-grid {
  margin-top: 0px !important;
}

.gci {
  margin: 0px 2px;
  width: unset !important;
  scale: 0.85;

  border: 1px solid #e7edf4;
  /* ss */

  box-shadow: -10px 8px 20px #f0f7fa;
  transition: 0.3s ease scale, 0.3s ease box-shadow;
}

.gci:hover {
  scale: 0.95;
  box-shadow: 0px 1.3531px 13.7062px var(--primary-color-light);
}

.brand-sec-margin-noboder .cat-cont::before {
  content: none !important;
}

.pci {
  scale: 0.95;
  border: 1px solid #e7edf4;
  /* ss */

  box-shadow: -10px 8px 20px #f0f7fa;
  transition: 0.3s ease scale, 0.3s ease box-shadow;
}

.pci:hover {
  scale: 1.015;
  box-shadow: 0px 1.3531px 13.7062px rgb(124 185 64 / 33%);
}

.slick-track .pci {
  scale: 0.85;
  border: 1px solid #e7edf4;
  /* ss */

  box-shadow: -10px 8px 20px #f0f7fa;
  transition: 0.3s ease scale, 0.3s ease box-shadow;
}

.slick-track .pci:hover {
  scale: 0.95;
  box-shadow: 0px 1.3531px 13.7062px rgb(124 185 64 / 33%);
}

.upload-back-1,
.upload-back-2 {
  position: absolute;
  opacity: 0.65;
}

.upload-back-1 {
  top: 0px;
  left: 0px;
}

.upload-back-2 {
  bottom: 0px;
  right: 0px;
}

.uploadpres-home-btn {
  display: flex;
  justify-content: center;
}

.uploadpres-home-btn a {
  width: fit-content !important;
  margin-top: 0px !important;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 73px;
  gap: 9px;

  border-radius: 30px !important;
}

.up-mini-icn {
  position: relative;
  width: 77%;
}

.up-mini-icn:before {
  content: "";
  display: block;
  padding-bottom: 100%;
  background-color: var(--primary-color-light);
  border-radius: 50%;
}

.up-mini-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* or 150% */

  display: flex;
  align-items: center;

  color: #5f5f5f;

  align-items: center;
  padding-right: calc(var(--bs-gutter-x) * 0.2) !important;
}

.up-mini-text p {
  margin-bottom: 0px;
}

.up-mini-icn-parent {
  display: flex;
  justify-content: center;
  padding-right: 0px;
  padding-left: calc(var(--bs-gutter-x) * 0.2) !important;
}

.up-mini-icn svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.up-h-incs {
  margin: 33px 110px;
}

.uppload-prec-container {
  z-index: 1;
  position: relative;
}

.banner-container.slick-container {
  padding: 0px !important;
}

.hover-drop {
  display: block;
  position: relative;
}

.hover-drop a {
  display: flex;
}

.langSelect {
  display: flex;
  gap: 3px;
  min-width: 85px;
}

.hover-drop a .icon-globe.globe {
  width: 16px;
  margin-right: 10px;
}

.hover-drop .sub-menu {
  position: absolute;
}

.fcol.col-lg-2.col-md-2 {
  width: 20%;
}

.allcategories-section .container {
  background: #ffffff;
  border-radius: 4px;
  padding: var(--bs-gutter-x, 1.3rem) var(--bs-gutter-x, 1.7rem);
}

.gac .gci {
  scale: 0.95;
}

.gac .gci:hover {
  scale: 1.015;
}

.allbrands-section .container {
  background: #ffffff;
  border-radius: 4px;
  padding: var(--bs-gutter-x, 1.3rem) var(--bs-gutter-x, 1.7rem);
}

.allbrands-section .lowerContent .item-name {
  display: none;
}

.gci .lowerContent .item-count {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 47px;
}

.gci .lowerContent .item-count div {
  border: 0.801527px solid #e7edf4;
  border-radius: 40.0763px;

  padding: 10px 21px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16.0305px;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: #5f5f5f;
  width: 123px;
  text-align: center;
  transition: all 0.3s ease;
}

.gci:hover .lowerContent .item-count div {
  background: var(--primary-color);
  color: #fff;
}

.gci .lowerContent {
  margin: 0px 16px 9px 16px;
}

.gci:hover .lowerContent .ic-1 {
  display: none;
}

.gci .lowerContent .ic-2 {
  display: none;
}

.gci:hover .lowerContent .ic-2 {
  display: block;
}

.gci .upperContent img {
  padding: 10px;
}

.mobile-grid.brand-widget .item-name {
  display: none;
}

.cat-cont .gci .lowerContent .item-count {
  display: none;
}

.all-back .container {
  background: #ffffff;
  border-radius: 4px;
  padding: var(--bs-gutter-x, 1.3rem) var(--bs-gutter-x, 1.7rem);
}

.prduct-list-parent .allProducts {
  margin-top: 0px;
}

.cartnew {
  border: 1px solid var(--primary-color);
  box-shadow: none !important;
}

.cartnew.expanded {
  width: 120px;
  justify-content: start;
  padding: 0px 15px;
}

.cartnew.expanded.profile-btn {
  width: 94px;
  justify-content: start;
  padding: 0px 15px;
  margin-left: 16px;
  cursor: pointer;
}

.MuiModal-root.MuiPopover-root .MuiPaper-root.MuiPaper-elevation {
  background-color: #f5f5f6 !important;
}

.MuiModal-root.MuiPopover-root .MuiPaper-root.MuiPaper-elevation::before {
  background-color: #f5f5f6 !important;
}

.MuiList-root.MuiMenu-list {
  background-color: #fff !important;
  padding: 0px !important;
  width: 100%;
}

.MuiList-root.MuiMenu-list .MuiDivider-fullWidth {
  background-color: #cbcbcb !important;
  border-color: #cbcbcb !important;
}

.profiledrop-img-heade {
  position: relative;
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  /* fg */
  padding-top: 28px;
  color: #5f5f5f;
  padding-bottom: 10px;
}

.profiledrop-img-heade img {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  top: -39px;
}

.out-cont.container {
  padding: 0px;
  background: #fff0 !important;
}

.outlet-list-parent {
  position: absolute;

  bottom: -345px;
  width: 95%;
  left: 50%;
  transform: translateX(-50%);
}

/* .outlet-list{
  margin-left: 30px;
  margin-right: 30px;
  width: calc(100% - 84px);
  border-radius: 20px !important;
  position: absolute;
  top: -223px;
}

.out-cont .map-parent {
  position: relative;
  margin-bottom: 182px;
} */

.outlet-list-img svg {
  scale: 1.3;
}

.themeform .MuiInput-root.MuiInput-underline.MuiInputBase-root {
  border: 1px solid rgba(89, 89, 89, 0.3);
  border-radius: 8px;
  padding: 17px 15px 6px 15px;
}

.themeform .MuiInput-root.MuiInput-underline.MuiInputBase-root::after,
.themeform .MuiInput-root.MuiInput-underline.MuiInputBase-root::before {
  outline: none !important;
  border-bottom: none !important;
}

.themeform .MuiInputLabel-root.MuiInputLabel-formControl {
  left: 15px !important;
  top: 13px;
  color: #757575;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.02rem;
}

.themeform .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink {
  transform: translate(0, 7.2px) scale(0.75) !important;
  font-size: 0.85rem;
}

/* BUTTONS CONFIGURATION */

.btn-main {
  border: none;
  background: #fff0;
  border-radius: 50px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  /* identical to box height */

  padding: 9px 63px;
  color: #ffffff;
}

.btn-main img {
  padding-right: 10px;
}

.btn-solid-primary {
  background: var(--linear-gradient);
}

.btn-solid-dark {
  background: #172026;
}

.btn-outline {
  border-radius: 20px;

  border: 1px solid black;
  background: #fff0;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  /* identical to box height */

  padding: 9px 15px;
  color: #000;
}

.btn-outline img {
  padding-right: 10px;
  max-width: 27px;
}

.btn-outline.btn-out-green {
  border-color: var(--primary-color);
}

.btn-outline.btn-out-blue {
  border-color: #235196;
  color: #235196;
}

.btn-outline.btn-out-gray {
  border-color: #cbcbcb;
  color: #5f5f5f;
}

.btn-outline.btn-out-red {
  border-color: #5f5f5f;
  color: #ed4c5c;
}

.defoutl-footer {
  margin-top: 20px;
}

.defoutl-footer .header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  display: flex;
  align-items: center;
  margin-top: 4px;
  /* fg */

  color: #5f5f5f;
}

.defoutl-footer .header>span {
  /* fg */

  color: #000;
}

.featuredPcontainer {
  background-color: #fff;
  padding-top: 25px;
}

.certifications {
  width: 100%;
  max-width: 100%;
}

.certifications img {
  width: 100%;
  max-width: 300px;
}

.main_expired {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.212);
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_expired .expired {
  background-color: #fff;
  width: 680px;
  max-width: 680px;
  margin: auto;
  padding: 30px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: auto 20px;
}

.main_expired .expired .upper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.main_expired .expired .upper h2 {
  text-align: center;
  font-size: 24px;
  color: #ff4c5e;
}

.main_expired .expired .contect {
  background-color: #f4f8ff;
  padding: 20px;
  border-radius: 12px;
  width: 100%;
}

.main_expired .expired .contect .subCon {
  max-width: 300px;
  margin: auto;
}

.main_expired .expired .contect .subCon p {
  font-weight: 600;
  font-size: 16px;
  line-height: 21.86px;
}

.main_expired .expired .contect .subCon .contect_info {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.main_expired .expired .contect .subCon .contect_info .info {
  display: flex;
  gap: 6px;
  align-items: center;
}

.main_expired .expired .contect .subCon .contect_info .info svg {
  width: 20px;
  height: 20px;
}

.main_expired .expired .contect .subCon .contect_info .info span {
  font-weight: 600;
  font-size: 16px;
  color: var(--primary-color);
}

.payment-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background-color: #343434bd;
  z-index: 50;
}

.CountryCodeForAddress {
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
}

.CountryCodeForAddress .MuiFormControl-root .countryCOdeparent {
  border-bottom: 2px solid;
  padding: 1px;
}

.vsam {
  font-weight: 600;
  color: var(--primary-color);
}



