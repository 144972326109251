.map-parent{
  position: relative;
}

.serach-map-parent{
  position: absolute;
    top: 9px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    width: 97%;

}

.serach-map-parent  input{
 
    width: 100%;
    width: 100%;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    border-radius: 2px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    border: none;
    height: 35px;
}


.serach-map-parent  input:focus-visible{
 
  width: 100%;
  width: 100%;
  box-shadow: rgb(255 228 98) 0px 1px 4px -1px;
  outline: none;
}
.get-current-location{
  position: absolute;
  bottom: 24px;
  left: 10px;
  z-index: 2;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    border-radius: 2px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    border: none;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.get-current-location img {
  width: 100%;
}
.gm-style-cc{display: none;}

[data-reach-combobox-popover] {
  border: none !important
}