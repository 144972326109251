@use "../abstracts/" as abs;

.btnr {
    padding: 15px 30px;
    font-family: abs.$font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    line-height: 150%;
    display: inline-flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    border-radius: 90px !important;
    border: 1px solid black;
    letter-spacing: 0.1em;
    text-decoration: none !important;
    // text-transform: uppercase !important;

    // hover state
    position: relative;
    overflow: hidden;
    z-index: 1;

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.15);
        transition: all 0.4s;
        border-radius: 10rem;
        z-index: -1;
    }

    &:hover {
        color: #fff !important;

        &:before {
            width: 100%;
        }
    }

    &.btnr-primary {
        background-color: abs.$primary-color;
        color: abs.$text-white;
        border-color: abs.$primary-color;

        &:disabled {
            background-color: abs.$primary-color;
            color: abs.$text-white;
            border-color: abs.$primary-color;
            opacity: 0.3 !important;
        }
    }

    &.btnr-primary-outline {
        background-color: #fff;
        color: abs.$primary-color;
        border-color: abs.$primary-color;
      }
}