/* .rtl-main{
 direction: rtl;
}
 */
 [dir='rtl'] body {
  font-family: 'Tajawal', sans-serif !important;
}


 [dir='rtl']  .right-border{
  right: unset;
  left: 0px;
}

[dir='rtl'] .select-store {
  left: unset;
  right: 57px;
}

[dir='rtl']  .searchIcon {
  margin-right: 0px;
  margin-left: 7px;
}

[dir='rtl'] .row-app-section2 .screenshot-home-app {
  
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 17px;
  right: -3px;
  left: auto;

}

[dir='rtl'] .app-btton img {
  margin-right: 0px;
  margin-left: 14px;
}

[dir='rtl'] footer .app-btton {

  padding: 9px 22px !important
}


[dir='rtl'] footer .app-btton:first-child {

  margin-right: 0rem !important;
  margin-left: 0.25rem !important;
}

[dir='rtl'] footer .app-btton:last-child {

  margin-right: 0.25rem !important;
  margin-left: 0rem !important;
}


[dir='rtl'] .qlinks {
  padding-left: 40px;
  padding-right: 0px;
}

[dir='rtl'] .qlinks2 {
  /* padding-left: 40px; */
  padding-right: 0px;
}

[dir='rtl'] .plinks2 {
  /* padding-left: 40px; */
  padding-right: 0px;
}

[dir='rtl'] .reach-us-image {
  margin-right: 0px;
  margin-left: 10px;
}
[dir='rtl'] .doctor-image {
  margin-right: 0px;
  margin-left: 10px;
}

@media (max-width: 575.98px) {
[dir='rtl'] .hover-drop:hover>.sub-menu {
  transform: translateX(-144px);
}
}

[dir='rtl'] .form-check-label {
 
  padding-right: 34px;
  padding-left: 0px;

}

[dir='rtl'] .form-check-radio label:before {
 
  margin-right: 0px;
  margin-left: 11px;
  left: unset;
  right: 0px;
}

[dir='rtl'] .form-check-radio input:checked + label:after {
 
  left: unset;
  right: 0px;
 

}

[dir='rtl'] .os-text {
  text-align: right;
}

[dir='rtl']  .os-numbers {
  text-align: left;
}


[dir='rtl'] .slick-slide {
  float: left !important;
}


[dir='rtl'] .slick-container .slick-next:before {
  top: 0px !important;
  left: 0px !important;
  content: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon-icon-5Yc"><polyline points="15 18 9 12 15 6"></polyline></svg>') !important;
}

[dir='rtl'] .slick-container .slick-prev:before {
  top: 0px !important;
  left: 0px !important;
  content: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon-icon-5Yc"><polyline points="9 18 15 12 9 6"></polyline></svg>');
}

[dir='rtl'] .pci .lowerContent .product-op {
  
  margin-left: 0px;
  margin-right: 11px;
}

[dir='rtl'] .account-photo {
  border: 8px solid #ffffff;
  border-radius: 91.5px;
  width: 183px;
  height: 183px;
  overflow: hidden;
  position: absolute;
  bottom: -59px;
  left: unset;
  right: 27px;
}

[dir='rtl'] .order-details-address-div-button-edt {
 
  right: unset;
  left: 9px;
  
}

[dir='rtl'] .pd-btn > img, [dir='rtl']  .pd-btn > svg {
  margin-left: 10px;
  margin-right: 0px;
}


[dir='rtl'] .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated{
  left: unset !important;
  right: 0;
 
}

[dir='rtl'] .MuiAutocomplete-endAdornment {
  right: unset !important;
  left: 0 ;
}

[dir='rtl'] .getmyloc img {
  padding-right: 0px;
  padding-left: 10px;
}

[dir='rtl'] .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-root .MuiAutocomplete-inputRoot {
  padding-left: 30px;
  padding-right: 0px !important;
}

[dir='rtl'] .header-1 span .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium{
 transform: scale(-1);
}

[dir='rtl'] .header-1 span {
  padding-right: 0px;
  padding-left: 7px;
}

[dir='rtl'] .MuiPagination-root .MuiPaginationItem-previousNext svg{
  transform: scale(-1) !important;
}

[dir='rtl'] li.hover-drop img{

  margin-right: 0px;
  margin-left: 10px;
}

[dir='rtl'] .react-tel-input .form-control {
  
  padding-left: 0.75rem;
  padding-right: 48px;
 
}

[dir='rtl'] .react-tel-input .selected-flag .arrow {
  
  left: unset !important;
  right: 20px;
 
}

[dir='rtl'] .btn-primary .spinner-border {
  margin-left: 10px;
  margin-right: 0px;
}

[dir='rtl'] .MuiStepConnector-root.MuiStepConnector-horizontal {
  left: calc(50% + 16px);
  right: calc(-50% + 16px);
}

[dir='rtl'] .outlet-prent-order-icon {
  
  margin-left: 15px;
  margin-right: 0px;
  
}

[dir='rtl'] .od-sum-value,
[dir='rtl'] .od-tot-value  {
  
  text-align: left;
 
}


[dir='rtl'] .accordion-button::after  {
  
  margin-right: auto !important;
  margin-left: 0px !important;
}


[dir='rtl'] .cat-filtrrr .accordion-body ul {
  
  padding-right: 0.5rem;
  padding-left: 2rem;
}


[dir='rtl'] .cart-drop {
  
  left: 0px;
  right: unset;
}


[dir='rtl'] .cart-ind-text {
  
  margin-right: 6px;
  margin-left: 6px;
}

[dir='rtl'] .out-btn span {
  padding-left: 0px;
  padding-right: 15px;
  
}
[dir='rtl'] .INoutlet-selector {
  
  border-right: none;
  border-left: 1px solid rgba(124, 185, 64, 0.2);
  
}

[dir='rtl'] .outlet-widget-inc {
  
  margin-right: 0px;
  margin-left: 10px;
}
[dir='rtl'] .dec-icn {
 
  margin-right: 0px;
  margin-left: 11px;
}
[dir='rtl'] .outlet-widget-text-2-2 {
  
  margin-left: 0px;
  margin-right: 5px;
}
[dir='rtl'] .outlet-widget-text-3-1 {
  margin-right: 0px;
  margin-left: 7px;
}
[dir='rtl'] .out-btn input {
  padding-right: 15px;
  padding-left: 0px;
  
}
[dir='rtl'] .search-item-icon {
 
  margin-right: 0px;
  margin-left: 13px;
}
