@use "../abstracts/" as abs;

.dataDeletionMain {
    .DeletionContent {
        .sec_1 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 30px;
            margin: 100px auto;

            .info {
                display: flex;
                flex-direction: column;
                gap: 30px;
                justify-content: center;

                h1 {
                    font-size: abs.$heading-xxl;
                    color: abs.$primary-color;
                    font-weight: abs.$font-semibold;
                }

                p {
                    font-size: abs.$heading-sm;
                    font-weight: abs.$font-regular;
                    color: abs.$primary-color;
                }
            }

            .image {
                max-width: 100%;

                img {
                    max-width: 100%;
                    height: auto;
                    aspect-ratio: 583 / 590;
                }
            }
        }

        .sec_2 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 30px;
            margin: 100px auto;

            .info {
                display: flex;
                flex-direction: column;
                gap: 30px;
                justify-content: center;

                h1 {
                    font-size: abs.$heading-xl;
                    color: abs.$primary-color;
                    font-weight: abs.$font-medium;
                }

                p {
                    font-size: abs.$heading-xs;
                    font-weight: abs.$font-small;
                    color: abs.$primary-color;
                }

                .contectMain {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 20px;

                    .contectItem {

                        h3 {
                            font-size: abs.$heading-md;
                            color: abs.$secondary-color;
                        }

                        .conInfo {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            font-size: abs.$heading-xs;
                            color: abs.$primary-color;
                        }
                    }
                }
            }

            .image {
                max-width: 100%;

                img {
                    max-width: 100%;
                    height: auto;
                    aspect-ratio: 550 / 401;
                }
            }
        }

        .sec_3 {
            .heading {
                text-align: center;
                max-width: 700px;
                margin: auto;
                padding-bottom: 60px;
                background-color: #f5f5f5;
                position: relative;
                z-index: 1;

                h1 {
                    font-size: abs.$heading-xl;
                    color: abs.$primary-color;
                    font-weight: abs.$font-medium;
                }
            }

            .stepsMain {
                display: flex;
                flex-direction: column;
                gap: 50px;
                padding: 50px;

                .step {
                    width: 100%;
                    display: flex;
                    position: relative;

                    .line {
                        position: absolute;
                        left: 50%;
                        bottom: 50%;
                        transform: translateX(-50%);
                        z-index: 0;
                    }
                    
                    &.left {
                        justify-content: end;
                    }
                    
                    .subStep {
                        background-color: #fff;
                        padding: 48px 34px;
                        box-shadow: 0px 36.44px 61.12px 0px #0F153117;
                        position: relative;
                        max-width: 400px;
                        z-index: 1;

                        h3 {
                            font-size: abs.$heading-sm;
                            font-weight: abs.$font-regular;
                        }

                        p {
                            font-size: abs.$body;
                            font-weight: abs.$font-small;
                        }

                        .icon {
                            position: absolute;
                            top: 10px;
                            left: 18px;
                        }
                    }
                }
            }
        }

        .requestArea {
            background-color: #fff;
            border-radius: 33px;
            padding: 40px 60px;
            position: relative;
            margin-bottom: 50px;

            .info {
                max-width: 500px;
                display: flex;
                flex-direction: column;
                gap: 20px;
                align-items: start;


                h1 {
                    font-size: abs.$heading-lg;
                    color: abs.$primary-color;
                    font-weight: abs.$font-medium;
                }

                p {
                    font-size: abs.$heading-xs;
                    color: abs.$primary-color;
                    font-weight: abs.$font-small;
                }

                .button {
                    padding: 20px 35px;
                    background-color: #FF3D00;
                    color: #fff;
                    font-size: abs.$button-xl;
                    display: flex;
                    gap: 5px;
                    border: none;
                    border-radius: 12px;
                    align-items: center;
                }
            }

            .image {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: auto;
                max-height: 115%;
                aspect-ratio: 494 / 395;
            }
        }

    }
}